/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        // Navigation
        var navMenu = $("#nav.main__nav");
        var navOpen = $("#nav__open");
        var navClose = $("#nav__close");

        $("#header").focusin(function (e) {
          if (!$.contains($(navMenu)[0], document.activeElement)) {
            navClose.trigger("click");
          }
        });

        // keyboard navigation
        navMenu.on("keydown", function (event) {
          // keyCode -> http://www.javascripter.net/faq/keycodes.htm
          // 27 = esc
          if (event.keyCode === 27) {
            navClose.trigger("click");
          }
        });

        // open navigation
        navOpen.click(function () {
          navOpen.attr(
            "aria-expanded",
            navOpen.attr("aria-expanded") === "false" ? "true" : "false"
          );
          navClose.attr(
            "aria-expanded",
            navClose.attr("aria-expanded") === "false" ? "true" : "false"
          );
          navMenu.addClass("open");
          $("html, body").addClass("main__nav-opened");
        });

        // close navigation
        navClose.click(function () {
          navOpen.attr(
            "aria-expanded",
            navOpen.attr("aria-expanded") === "false" ? "true" : "false"
          );
          navClose.attr(
            "aria-expanded",
            navClose.attr("aria-expanded") === "false" ? "true" : "false"
          );
          navMenu.removeClass("open");
          $("html, body").removeClass("main__nav-opened");
        });

        // toggle sub-menu
        var menuMainItems = document.querySelectorAll(
          ".main_menu li.menu-item-has-children"
        );

        function setAria() {
          if ($(window).width() > 820) {
            menuMainItems.forEach(function (item) {
              item.querySelector("a").setAttribute("aria-expanded", "true");
              item.querySelector("a").setAttribute("tabindex", "-1");
            });
          } else {
            menuMainItems.forEach(function (item) {
              if (item.classList.contains("open")) {
                item.querySelector("a").setAttribute("aria-expanded", "true");
                item.querySelector("a").removeAttribute("tabindex");
              } else {
                item.querySelector("a").setAttribute("aria-expanded", "false");
                item.querySelector("a").removeAttribute("tabindex");
              }
            });
          }
        }
        setAria();
        $(window).resize(function () {
          setAria();
        });

        Array.prototype.forEach.call(menuMainItems, function (el, i) {
          el.querySelector("a").addEventListener("click", function (event) {
            if (this.parentNode.classList.contains("open")) {
              this.parentNode.classList.remove("open");
              this.setAttribute("aria-expanded", "false");
            } else {
              this.parentNode.classList.add("open");
              this.setAttribute("aria-expanded", "true");
            }
            if (event.target.href.indexOf("#") != -1) {
              event.preventDefault();
              //event.stopPropagation();
              return false;
            }
          });
        });

        var menuHeaderItems = document.querySelectorAll(
          ".header_menu li.menu-item-has-children"
        );
        Array.prototype.forEach.call(menuHeaderItems, function (el, i) {
          // focus
          el.addEventListener("focusin", function (event) {
            this.classList.add("open");
            this.querySelector("a").setAttribute("aria-expanded", "true");
          });
          el.addEventListener("focusout", function (event) {
            this.classList.remove("open");
            this.querySelector("a").setAttribute("aria-expanded", "false");
          });

          // mouse
          el.addEventListener("mouseover", function (event) {
            this.classList.add("open");
            this.querySelector("a").setAttribute("aria-expanded", "true");
          });
          el.addEventListener("mouseout", function (event) {
            this.classList.remove("open");
            this.querySelector("a").setAttribute("aria-expanded", "false");
          });

          // click
          el.querySelector("a").addEventListener("click", function (event) {
            if (this.parentNode.classList.contains("open")) {
              this.parentNode.classList.remove("open");
              this.setAttribute("aria-expanded", "false");
            } else {
              this.parentNode.classList.add("open");
              this.setAttribute("aria-expanded", "true");
            }
            event.preventDefault();
            return false;
          });
        });

        // AOS - animate on scroll
        // setting
        AOS.init({
          offset: 120,
          once: true,
        });

        document.querySelectorAll("img").forEach(function (img) {
          img.addEventListener("load", function () {
            AOS.refresh();
          });
        });

        // Slider
        var gallerySlider = $(".text--wysiwyg .gallery");

        gallerySlider.slick({
          arrows: false,
          autoplay: false,
          autoplaySpeed: 4000,
          infinite: true,
          pauseOnHover: true,
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
          rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
        });

        var gallerySliderSingle = $(".gallery--single");

        gallerySliderSingle.slick({
          arrows: false,
          autoplay: true,
          autoplaySpeed: 4000,
          infinite: true,
          pauseOnHover: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
        });

        // Magnific Popup
        // Image
        var magPopImg = $(".magnificpopup");
        magPopImg.magnificPopup({
          type: "image",
          image: {
            titleSrc: function (item) {
              return item.el.find("img").attr("alt");
            },
          },
        });

        // Video
        var magPopVideo = $(".magnificpopup--video");
        magPopVideo.magnificPopup({
          type: "iframe",
          iframe: {
            markup:
              '<div class="mfp-iframe-scaler">' +
              '<div class="mfp-close"></div>' +
              '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
              "</div>", // HTML markup of popup, `mfp-close` will be replaced by the close button
            patterns: {
              youtube: {
                index: "youtube.com/", // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                id: "v=", // String that splits URL in a two parts, second part should be %id%
                // Or null - full URL will be returned
                // Or a function that should return %id%, for example:
                // id: function(url) { return 'parsed id'; }

                src: "//www.youtube.com/embed/%id%", // URL that will be set as a source for iframe.
              },
            },
          },
        });

        // Gallery
        var magPopGallery = $(".magnificpopup--gallery");
        magPopGallery.each(function () {
          // the containers for all your galleries

          $(this)
            .find("a")
            .magnificPopup({
              //delegate: 'a', // the selector for gallery item
              type: "image",
              gallery: {
                enabled: true,
              },
              image: {
                titleSrc: function (item) {
                  return item.el.find("img").attr("alt");
                },
              },
            });
        });

        // Gallery
        var wysiwygGallery = $(".text--wysiwyg .gallery");
        wysiwygGallery.each(function () {
          // the containers for all your galleries

          $(this)
            .find("a")
            .magnificPopup({
              //delegate: 'a', // the selector for gallery item
              type: "image",
              gallery: {
                enabled: true,
              },
              image: {
                titleSrc: function (item) {
                  return item.el.find("img").attr("alt");
                },
              },
            });
        });

        // Arrow to top
        var top_arrow = $(".top_arrow");
        var htmlBody = $("html, body");
        window.addEventListener("scroll", function () {
          var distanceTop = htmlBody.scrollTop();
          if (distanceTop > 100) {
            top_arrow.fadeIn();
          } else {
            top_arrow.fadeOut();
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },

    // Page template Homepage
    page_template_homepage: {
      init: function () {
        // JavaScript to be fired

        // Slider
        var homeSlider = $(".home--slider .items");

        homeSlider.slick({
          arrows: false,
          autoplay: true,
          autoplaySpeed: 5000,
          infinite: false,
          rewind: false,
          pauseOnHover: false,
          dots: true,
          mouseDrag: false,
          touchDrag: false,
          pullDrag: false,
          customPaging: function (slider, i) {
            return (
              '<button type="button" tabindex="0"><span class="number_accent">0' +
              (i + 1) +
              '</span><span class="number">0' +
              (i + 1) +
              '</span> <span class="label">' +
              homeSlider
                .find(".item:nth-of-type(" + (i + 1) + ") .title2")
                .text() +
              "</span></button>"
            );
          },
          rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
        });
      },
      finalize: function () {
        // JavaScript to be fired, after page specific JS is fired
      },
    },

    // Product single
    single_product: {
      init: function () {
        // JavaScript to be fired on the home page

        function openDetail() {
          var urlHash = window.location.hash.slice(1);
          var urlHashElem = document.getElementById(urlHash);
          if (urlHashElem) {
            urlHashElem.open = true;
          }
        }
        openDetail();
        window.addEventListener("hashchange", function () {
          console.log("hashchange");
          openDetail();
        });

        var galleryProduct = $(".product--single .gallery .items");
        galleryProduct.slick({
          arrows: false,
          autoplay: false,
          autoplaySpeed: 4000,
          infinite: false,
          pauseOnHover: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
        });
        // fix galleryProduct on details Ends open
        var detailsEnds = document.querySelector(".product--ends");
        if (detailsEnds) {
          detailsEnds.addEventListener("toggle", function () {
            if (detailsEnds.hasAttribute("open")) {
              galleryProduct.slick("refresh");
            }
          });
        }
        // fix galleryProduct on details NoEnds open
        var detailsNoEnds = document.querySelector(".product--noends");
        if (detailsNoEnds) {
          detailsNoEnds.addEventListener("toggle", function () {
            if (detailsNoEnds.hasAttribute("open")) {
              galleryProduct.slick("refresh");
            }
          });
        }

        // hide empty cols td and realative th
        function hideEmptyCols(table) {
          //count # of columns
          var numCols = $("th", table).length;
          for (var i = 1; i <= numCols; i++) {
            var empty = true;
            //grab all the <td>'s of the column at i
            $("td:nth-child(" + i + ")", table).each(function (index, el) {
              //check if the <span> of this <td> is empty
              if ($(this, el).text() !== "") {
                empty = false;
                return false; //break out of each() early
              }
            });
            if (empty) {
              $("td:nth-child(" + i + ")", table).hide(); //hide <td>'s
              $("th:nth-child(" + i + ")", table).hide(); //hide header <th>
              $("th:nth-child(1)", table).show();
              $("td:nth-child(1)", table).show();
            }
          }
        }
        var infoTable = document.querySelectorAll(".info--table");
        infoTable.forEach(function (e) {
          hideEmptyCols(e);
        });
        var modelsTable = document.querySelectorAll(".model--table");
        modelsTable.forEach(function (e) {
          hideEmptyCols(e);
        });

        // Unit filter
        var unitButton = $(".filter--unit button");
        var unitButtonFirst = $(".filter--unit button:nth-of-type(1)");

        unitButtonFirst.addClass("active");

        unitButton.each(function () {
          $(this).click(function () {
            var active = $(this).hasClass("active");
            var unit = $(this).attr("class");

            var unitTH = $(this)
              .parent()
              .parent()
              .parent()
              .parent()
              .find(".model--table th.unit span.unit");
            var unitTD = $(this)
              .parent()
              .parent()
              .parent()
              .parent()
              .find(".model--table td.unit");

            if (unit === "unit--metric") {
              unitTH.each(function () {
                var unitData = $(this).attr("data-metric");
                $(this).text(unitData);
              });
              unitTD.each(function () {
                var unitData = $(this).attr("data-metric");
                var unitDatameasure = $(this).attr("data-unit-metric");
                $(this).attr("data-unit", unitDatameasure);
                $(this).text(unitData);
              });
            } else {
              unitTH.each(function () {
                var unitData = $(this).attr("data-imperial");
                $(this).text(unitData);
              });
              unitTD.each(function () {
                var unitData = $(this).attr("data-imperial");
                var unitDatameasure = $(this).attr("data-unit-imperial");
                $(this).attr("data-unit", unitDatameasure);
                $(this).text(unitData);
              });
            }

            if (active === true) {
              $(this).removeClass("active");
              $(this)
                .parent()
                .find(":not(." + unit + ")")
                .addClass("active");
            } else {
              $(this).addClass("active");
              $(this)
                .parent()
                .find(":not(." + unit + ")")
                .removeClass("active");
            }
          });
        });

        // Material filter
        var materialButton = $(".filter--material button");
        var materialButtonFirst = $(".filter--material button:nth-of-type(1)");

        materialButtonFirst.addClass("active");
        materialButtonFirst
          .parent()
          .parent()
          .parent()
          .parent()
          .find(".model--table:nth-of-type(2)")
          .addClass("none");

        materialButton.each(function () {
          $(this).click(function () {
            var active = $(this).hasClass("active");
            var material = $(this).attr("class");

            if (active === true) {
              // console.log('true');
              $(this).removeClass("active");
              $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .find(".model--table." + material)
                .addClass("none");
              $(this)
                .parent()
                .find(":not(." + material + ")")
                .addClass("active");
              $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .find(".model--table:not(." + material + ")")
                .removeClass("none");
            } else {
              // console.log('false');
              $(this).addClass("active");
              $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .find(".model--table." + material)
                .removeClass("none");
              $(this)
                .parent()
                .find(":not(." + material + ")")
                .removeClass("active");
              $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .find(".model--table:not(." + material + ")")
                .addClass("none");
            }
          });
        });

        // popup addToCart
        // variabili condivise popup
        var baseUrlWP = document.querySelector("#baseUrlWP").value;
        var currentUserName = document.querySelector("#currentUserName");
        if (currentUserName) {
          currentUserName = currentUserName.value;
        }
        var currentUserId = document.querySelector("#currentUserId");
        if (currentUserId) {
          currentUserId = currentUserId.value;
        }
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var currentDate = year + "-" + month + "-" + day;

        // nonce
        var nonce = document.querySelector("#nonce");
        if (nonce) {
          nonce = nonce.value;
        }

        // campi TOP nel popup
        var ecommerceProductName = document.querySelector(
          "#ecommerceProductName"
        );
        var ecommerceProductMaterial = document.querySelector(
          "#ecommerceProductMaterial"
        );

        // table dati
        var tableData = document.querySelector(
          "#addToCartPopup .table_content"
        );
        if (tableData) {
          tableData.classList.add("hidden");
        }

        // dati tabella
        var ecommerceModelName = document.querySelector(
          "#addToCartPopup .modelName"
        );
        var ecommerceModelDesc = document.querySelector(
          "#addToCartPopup .modelDesc"
        );
        var ecommerceModelMaterial = document.querySelector(
          "#addToCartPopup .modelMaterial"
        );
        var ecommerceModelDN = document.querySelector(
          "#addToCartPopup .modelDN"
        );
        var ecommerceModelPrice = document.querySelector(
          "#addToCartPopup .modelPrice"
        );

        // preloader
        var preloaderPopup = document.querySelector(
          "#addToCartPopup .preloader"
        );
        if (preloaderPopup && preloaderPopup.classList.contains("hidden")) {
          preloaderPopup.classList.remove("hidden");
        }

        // result
        var resultBox = document.querySelector(
          "#addToCartPopup .resultMessage"
        );
        var resultBoxKo = document.querySelector(
          "#addToCartPopup .resultMessage .error"
        );
        var resultBoxOk = document.querySelector(
          "#addToCartPopup .resultMessage .message"
        );

        // input nascosti popup
        var unitPricePopup = document.querySelector("#unitPricePopup");
        var modelNamePopup = document.querySelector("#modelNamePopup");
        var modelDescPopup = document.querySelector("#modelDescPopup");
        var modelQtyPerParcelPopup = document.querySelector(
          "#modelQtyPerParcelPopup"
        );

        // icona Aggiungi a carrello
        var addToCartList = document.querySelectorAll(".addToCart");
        if (addToCartList) {
          addToCartList.forEach(function (item) {
            var thisElement = $(item);

            thisElement.magnificPopup({
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  // chiamata a servizio EOS per recupero prezzo
                  var parentTd = item.closest("td");
                  var ecommerce_product_name = parentTd.querySelector(
                    ".ecommerce_product_name"
                  ).value;
                  var ecommerce_product_material = parentTd.querySelector(
                    ".ecommerce_product_material"
                  ).value;
                  var ecommerce_model_id = parentTd.querySelector(
                    ".ecommerce_model_id"
                  ).value;
                  var ecommerce_model_name = parentTd.querySelector(
                    ".ecommerce_model_name"
                  ).value;
                  var ecommerce_model_description = parentTd.querySelector(
                    ".ecommerce_model_description"
                  ).value;
                  var ecommerce_model_qtyPerParcel = parentTd.querySelector(
                    ".ecommerce_model_qtyPerParcel"
                  ).value;
                  var ecommerce_model_dn = parentTd.querySelector(
                    ".ecommerce_model_dn"
                  ).value;

                  // aggiorno input hidden per l'evento aggiungi a carrello
                  modelNamePopup.value = ecommerce_model_name;
                  modelDescPopup.value = ecommerce_model_description;
                  modelQtyPerParcelPopup.value = ecommerce_model_qtyPerParcel;

                  // aggiorno campi TOP nel popup
                  ecommerceProductName.innerHTML = ecommerce_product_name;
                  ecommerceProductMaterial.querySelector(
                    "span"
                  ).innerHTML = ecommerce_product_material.replace("-", " ");
                  ecommerceProductMaterial.classList = ecommerce_product_material;

                  // recupero prezzo corrente da EOS
                  axios({
                    method: "get",
                    url:
                      baseUrlWP +
                      "gemels/v1/search-model/" +
                      ecommerce_model_name,
                    headers: {
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      //console.log(response, response.status, response.data.length);

                      if (
                        200 === response.status &&
                        response.data.value !== undefined
                      ) {
                        var dataModel = response.data.value;
                        var price = dataModel[0].price; // -> N.B. COMMENTATO TEMPORANEAMENTE PERCHE' I PREZZI SONO A 0 04/10/2023
                        //price = 0;

                        var listinoUtenteId = "listino_" + currentUserName;

                        // aggiorno campo nascosto
                        unitPricePopup.value = price;
                        -(
                          // recupero listino dell'utente "user_price"
                          axios({
                            method: "get",
                            url:
                              baseUrlWP +
                              "wp/v2/user-price/?search=" +
                              listinoUtenteId,
                            headers: {
                              "X-WP-Nonce": nonce,
                            },
                          })
                            .then(function (response) {
                              //console.log(response, response.status, response.data.length);

                              if (200 === response.status) {
                                var methodToCall = "";
                                var urlToCall = "";
                                var currentListinoRows = [];
                                var oldListinoRows = [];

                                var newItem = {
                                  user_prices_rows_model: ecommerce_model_name,
                                  user_prices_rows_price: price,
                                  user_prices_rows_modifiedDate: new Date(),
                                };

                                // ho trovato già un listino per l'utente, lo aggiorno con PUT method
                                if (response.data.length > 0) {
                                  methodToCall = "put";
                                  urlToCall =
                                    baseUrlWP +
                                    "wp/v2/user-price/" +
                                    response.data[0].id;

                                  // recupero l'elenco di prezzi già inseriti nel listino, cerco il codice modello e lo aggiorno o aggiungo
                                  var modelFound = false;
                                  oldListinoRows =
                                    response.data[0].acf.user_prices
                                      .user_prices_rows;
                                  oldListinoRows.forEach(function (
                                    item,
                                    index
                                  ) {
                                    //console.log(item, index, ecommerce_model_name, item.user_prices_rows_model);
                                    if (
                                      item.user_prices_rows_model ===
                                      ecommerce_model_name
                                    ) {
                                      // aggiorno prezzo
                                      oldListinoRows[
                                        index
                                      ].user_prices_rows_price = price;
                                      oldListinoRows[
                                        index
                                      ].user_prices_rows_modifiedDate = new Date();
                                      modelFound = true;
                                    }
                                  });

                                  if (modelFound) {
                                    // ho trovato il modello, quindi aggiorno solo il prezzo e la data
                                    currentListinoRows = oldListinoRows;
                                  } else {
                                    // non ho trovato il modello, quindi è un prezzo nuovo, lo aggiungo
                                    oldListinoRows.push(newItem);
                                    currentListinoRows = oldListinoRows;
                                  }
                                } else {
                                  // non ho trovato un listino, lo creo con POST method
                                  methodToCall = "post";
                                  urlToCall = baseUrlWP + "wp/v2/user-price";

                                  currentListinoRows.push(newItem);
                                }

                                var postData = {
                                  title: listinoUtenteId,
                                  acf: {
                                    user_prices: {
                                      user_prices_user: parseInt(currentUserId),
                                      user_prices_rows: currentListinoRows,
                                    },
                                  },
                                  status: "publish",
                                };

                                // aggiungo il record
                                axios({
                                  method: methodToCall,
                                  url: urlToCall,
                                  data: postData,
                                  headers: {
                                    "Content-Type": "application/json",
                                    "X-WP-Nonce": nonce,
                                  },
                                })
                                  .then(function (response) {})
                                  .catch(function (error) {
                                    console.error(error);
                                  });
                              }
                            })
                            .catch(function (error) {
                              console.error(error);
                            })
                        );

                        // aggiorno dati TABLE nel popup

                        ecommerceModelName.innerHTML = ecommerce_model_name;
                        ecommerceModelDesc.innerHTML = ecommerce_model_description;
                        ecommerceModelMaterial.innerHTML = ecommerce_product_material.replace(
                          "-",
                          " "
                        );
                        ecommerceModelDN.innerHTML = ecommerce_model_dn;
                        ecommerceModelPrice.innerHTML =
                          "€ " + price.toString().replace(".", ","); // -> N.B. COMMENTATO TEMPORANEAMENTE PERCHE' I PREZZI SONO A 0 04/10/2023
                        //ecommerceModelPrice.innerHTML = "€ 0";

                        // quantità
                        var currentQty = document.querySelector("#qty");

                        // input aggiungi o togli quantità
                        var stepQnt =
                          ecommerce_model_qtyPerParcel === "0"
                            ? 1
                            : ecommerce_model_qtyPerParcel;
                        currentQty.value = stepQnt;
                        //console.log(stepQnt);

                        // eventi su + e -
                        var addQnt = document.querySelector(
                          "#addToCartPopup .modelQnt .piu"
                        );
                        var removeQnt = document.querySelector(
                          "#addToCartPopup .modelQnt .meno"
                        );
                        var oldQnt = parseInt(stepQnt);
                        var newQnt = parseInt(stepQnt);
                        if (addQnt && removeQnt) {
                          addQnt.addEventListener("click", function () {
                            newQnt += oldQnt;
                            currentQty.value = newQnt;
                          });

                          removeQnt.addEventListener("click", function () {
                            if (newQnt - oldQnt >= 1) {
                              newQnt -= oldQnt;
                              currentQty.value = newQnt;
                            }
                          });
                        }

                        // nascondo preloader
                        preloaderPopup.classList.add("hidden");

                        // mostro dati
                        if (tableData) {
                          tableData.classList.remove("hidden");
                        }
                      } else {
                        // messaggio
                        if (
                          resultBox &&
                          resultBox.classList.contains("hidden")
                        ) {
                          resultBox.classList.remove("hidden");
                        }

                        if (
                          resultBoxKo &&
                          resultBoxKo.classList.contains("hidden")
                        ) {
                          resultBoxKo.classList.remove("hidden");
                        }

                        if (resultBoxOk) {
                          resultBoxOk.classList.add("hidden");
                        }

                        // nascondo preloader
                        preloaderPopup.classList.add("hidden");
                      }
                    })
                    .catch(function (error) {
                      console.error(error);

                      // messaggio
                      if (resultBox && resultBox.classList.contains("hidden")) {
                        resultBox.classList.remove("hidden");
                      }

                      if (
                        resultBoxKo &&
                        resultBoxKo.classList.contains("hidden")
                      ) {
                        resultBoxKo.classList.remove("hidden");
                      }

                      if (resultBoxOk) {
                        resultBoxOk.classList.add("hidden");
                      }

                      // nascondo preloader
                      preloaderPopup.classList.add("hidden");
                    });
                },
                close: function () {
                  // resetto campi vari

                  // quantità
                  var currentQty = document.querySelector("#qty");
                  currentQty.value = 1;

                  modelDescPopup.value = "";
                  modelQtyPerParcelPopup.value = "";
                  tableData.classList.add("hidden");
                  preloaderPopup.classList.remove("hidden");

                  // messaggio
                  resultBox.classList.add("hidden");

                  if (
                    resultBoxOk &&
                    !resultBoxOk.classList.contains("hidden")
                  ) {
                    resultBoxOk.classList.add("hidden");
                  }

                  if (
                    resultBoxKo &&
                    !resultBoxKo.classList.contains("hidden")
                  ) {
                    resultBoxKo.classList.add("hidden");
                  }
                },
              },
            });
          });
        }

        // funzione aggiungi a carrello
        // aggiungo evento su pulsante "aggiungi a carrello"
        var ecommerceModelAddToCart = document.querySelector(
          "#addToCartPopup .bottom button"
        );
        if (ecommerceModelAddToCart) {
          ecommerceModelAddToCart.addEventListener("click", function (e) {
            e.stopPropagation();

            // nascondo tabella dati e mostro preloader
            tableData.classList.add("hidden");
            preloaderPopup.classList.remove("hidden");

            // aggiungo il record al carrello
            var cartUserId = document.querySelector("#currentUserId").value;
            var cartUserName = document.querySelector("#currentUserName").value;
            var cartUnitPrice = unitPricePopup.value;
            var cartModelName = modelNamePopup.value;
            var cartModelDesc = modelDescPopup.value;
            var cartQtyPerParcel = modelQtyPerParcelPopup.value;

            // quantità
            var currentQty = document.querySelector("#qty");
            var cartQty = currentQty.value;

            var cartId = "cart_" + cartUserName;

            // controllo prima se esiste già un carrello
            axios({
              method: "get",
              url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
              headers: {
                "X-WP-Nonce": nonce,
              },
            })
              .then(function (response) {
                console.log(
                  response,
                  response.status,
                  response.data.length,
                  response.data
                );

                if (200 === response.status) {
                  var methodToCall = "";
                  var urlToCall = "";
                  var currentCartRows = [];
                  var oldCartRows = [];

                  var newItem = {
                    ecommerce_cart_rows_model: cartModelName,
                    ecommerce_cart_rows_text: cartModelDesc,
                    ecommerce_cart_rows_unitPrice: cartUnitPrice,
                    ecommerce_cart_rows_qnt: parseInt(cartQty),
                    ecommerce_cart_rows_qtyPerParcel: parseInt(
                      cartQtyPerParcel
                    ),
                  };

                  // ho trovato già un carrello per l'utente, lo aggiorno con PUT method
                  if (response.data.length > 0) {
                    console.log("ho già un carrello");
                    methodToCall = "put";
                    urlToCall = baseUrlWP + "wp/v2/cart/" + response.data[0].id;

                    // recupero l'elenco di prodotti già inseriti a carrello ed aggiungo il nuovo in testata
                    oldCartRows =
                      response.data[0].acf.ecommerce_cart.ecommerce_cart_rows;

                    // se il carrello era vuoto, faccio push, altrimenti unshift
                    if (oldCartRows && oldCartRows.length > 0) {
                      currentCartRows = oldCartRows;
                      currentCartRows.unshift(newItem);
                    } else {
                      currentCartRows.push(newItem);
                    }
                  } else {
                    console.log("non ho un carrello");
                    // non ho trovato un carrello, lo creo con POST method
                    methodToCall = "post";
                    urlToCall = baseUrlWP + "wp/v2/cart";

                    currentCartRows.push(newItem);
                  }

                  var postData = {
                    title: cartId,
                    acf: {
                      ecommerce_cart: {
                        ecommerce_cart_user: parseInt(cartUserId),
                        ecommerce_cart_rows: currentCartRows,
                      },
                    },
                    status: "publish",
                  };

                  //console.log(currentCartRows);

                  // aggiungo il record
                  axios({
                    method: methodToCall,
                    url: urlToCall,
                    data: postData,
                    headers: {
                      "Content-Type": "application/json",
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      // nascondo preloader
                      preloaderPopup.classList.add("hidden");

                      // messaggio
                      if (resultBox && resultBox.classList.contains("hidden")) {
                        resultBox.classList.remove("hidden");
                      }

                      if (
                        resultBoxOk &&
                        resultBoxOk.classList.contains("hidden")
                      ) {
                        resultBoxOk.classList.remove("hidden");
                      }

                      if (resultBoxKo) {
                        resultBoxKo.classList.add("hidden");
                      }
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                }
              })
              .catch(function (error) {
                console.error(error);
              });
          });
        }

        // addToCart -> chiudi popup
        var addToCartClosePopup = document.querySelector(
          "#addToCartPopup .closePopup"
        );
        if (addToCartClosePopup) {
          addToCartClosePopup.addEventListener("click", function () {
            $.magnificPopup.close();
          });
        }

        // Wishlist

        // campi TOP nel popup
        var ecommerceProductNameWishlist = document.querySelector(
          "#ecommerceProductNameWishlist"
        );

        // table dati
        var tableDataWishlist = document.querySelector(
          "#addToWishlistPopup .table_content"
        );

        // dati tabella
        var ecommerceModelNameWishlist = document.querySelector(
          "#addToWishlistPopup .modelName"
        );
        var ecommerceModelDescWishlist = document.querySelector(
          "#addToWishlistPopup .modelDesc"
        );

        // preloader
        var preloaderPopupWishlist = document.querySelector(
          "#addToWishlistPopup .preloader"
        );

        // result
        var resultBoxWishlist = document.querySelector(
          "#addToWishlistPopup .resultMessage"
        );
        var resultBoxWishlistKo = document.querySelector(
          "#addToWishlistPopup .resultMessage .error"
        );
        var resultBoxWishlistOk = document.querySelector(
          "#addToWishlistPopup .resultMessage .message"
        );

        // input nascosti popup
        var productNamePopupWishlist = document.querySelector(
          "#productNameWishlistPopup"
        );
        var productIdPopupWishlist = document.querySelector(
          "#productIdWishlistPopup"
        );
        var modelIdPopupWishlist = document.querySelector(
          "#modelIdWishlistPopup"
        );
        var modelNamePopupWishlist = document.querySelector(
          "#modelNameWishlistPopup"
        );
        var modelDescPopupWishlist = document.querySelector(
          "#modelDescWishlistPopup"
        );

        var addToWishlistList = document.querySelectorAll(".addToWishlist");
        if (addToWishlistList) {
          addToWishlistList.forEach(function (item) {
            var thisElement = $(item);

            thisElement.magnificPopup({
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  var parentTd = item.closest("td");
                  var ecommerce_product_name = parentTd.querySelector(
                    ".ecommerce_product_name"
                  ).value;
                  var ecommerce_product_id = parentTd.querySelector(
                    ".ecommerce_product_id"
                  ).value;
                  var ecommerce_model_id = parentTd.querySelector(
                    ".ecommerce_model_id"
                  ).value;
                  var ecommerce_model_name = parentTd.querySelector(
                    ".ecommerce_model_name"
                  ).value;
                  var ecommerce_model_description = parentTd.querySelector(
                    ".ecommerce_model_description"
                  ).value;

                  // aggiorno input hidden per l'evento aggiungi a wishlist
                  productNamePopupWishlist.value = ecommerce_product_name;
                  productIdPopupWishlist.value = ecommerce_product_id;
                  modelIdPopupWishlist.value = ecommerce_model_id;
                  modelNamePopupWishlist.value = ecommerce_model_name;
                  modelDescPopupWishlist.value = ecommerce_model_description;

                  // aggiorno campi TOP nel popup
                  ecommerceProductNameWishlist.innerHTML = ecommerce_product_name;
                  ecommerceModelNameWishlist.innerHTML = ecommerce_model_name;
                  if (ecommerce_model_description !== "") {
                    ecommerceModelDescWishlist.innerHTML = ecommerce_model_description;
                  } else {
                    ecommerceModelDescWishlist.innerHTML = "-";
                  }
                },
                close: function () {
                  // resetto campi vari
                  if (tableDataWishlist.classList.contains("hidden")) {
                    tableDataWishlist.classList.remove("hidden");
                  }

                  // messaggio
                  resultBoxWishlist.classList.add("hidden");

                  if (
                    resultBoxWishlistOk &&
                    !resultBoxWishlistOk.classList.contains("hidden")
                  ) {
                    resultBoxWishlistOk.classList.add("hidden");
                  }

                  if (
                    resultBoxWishlistKo &&
                    !resultBoxWishlistKo.classList.contains("hidden")
                  ) {
                    resultBoxWishlistKo.classList.add("hidden");
                  }
                },
              },
            });
          });
        }

        // funzione aggiungi a wishlist
        // aggiungo evento su pulsante "aggiungi a wishlist"
        var ecommerceModelAddToWishlist = document.querySelector(
          "#addToWishlistPopup .bottom button"
        );
        if (ecommerceModelAddToWishlist) {
          ecommerceModelAddToWishlist.addEventListener("click", function (e) {
            e.stopPropagation();

            // nascondo tabella dati e mostro preloader
            tableDataWishlist.classList.add("hidden");
            preloaderPopupWishlist.classList.remove("hidden");

            // aggiungo il record alla wishlist
            var wishlistUserId = document.querySelector("#currentUserId").value;
            var wishlistUserName = document.querySelector("#currentUserName")
              .value;
            var wishlistProductId = productIdPopupWishlist.value;
            var wishlistModelId = modelIdPopupWishlist.value;
            var wishlistModelName = modelNamePopupWishlist.value;
            var wishlistModelDesc = modelDescPopupWishlist.value;

            var wishlistId = "wishlist_" + wishlistUserName;

            // controllo prima se esiste già una wishlist
            axios({
              method: "get",
              url: baseUrlWP + "wp/v2/wishlist/?search=" + wishlistId,
              headers: {
                "X-WP-Nonce": nonce,
              },
            })
              .then(function (response) {
                //console.log(response, response.status, response.data.length, response.data);

                if (200 === response.status) {
                  var methodToCall = "";
                  var urlToCall = "";
                  var currentWishlistRows = [];
                  var oldWishlistRows = [];

                  var newItem = {
                    ecommerce_wishlist_rows_model: parseInt(wishlistModelId),
                    ecommerce_wishlist_rows_family: parseInt(wishlistProductId),
                  };

                  // ho trovato già una wishlist per l'utente, lo aggiorno con PUT method
                  if (response.data.length > 0) {
                    console.log("ho già una wishlist");
                    methodToCall = "put";
                    urlToCall =
                      baseUrlWP + "wp/v2/wishlist/" + response.data[0].id;

                    // recupero l'elenco degli articoli già inseriti nella wishlist ed aggiungo il nuovo in testata
                    oldWishlistRows =
                      response.data[0].acf.ecommerce_wishlist
                        .ecommerce_wishlist_rows;

                    // se la wishlist era vuota, faccio push, altrimenti unshift
                    if (oldWishlistRows && oldWishlistRows.length > 0) {
                      currentWishlistRows = oldWishlistRows;
                      currentWishlistRows.unshift(newItem);
                    } else {
                      currentWishlistRows.push(newItem);
                    }
                  } else {
                    console.log("non ho una wishlist");
                    // non ho trovato una wishlist, la creo con POST method
                    methodToCall = "post";
                    urlToCall = baseUrlWP + "wp/v2/wishlist";

                    currentWishlistRows.push(newItem);
                  }

                  var postData = {
                    title: wishlistId,
                    acf: {
                      ecommerce_wishlist: {
                        ecommerce_wishlist_user: parseInt(wishlistUserId),
                        ecommerce_wishlist_rows: currentWishlistRows,
                      },
                    },
                    status: "publish",
                  };

                  //console.log(currentWishlistRows);

                  // aggiungo il record
                  axios({
                    method: methodToCall,
                    url: urlToCall,
                    data: postData,
                    headers: {
                      "Content-Type": "application/json",
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      // nascondo preloader
                      preloaderPopupWishlist.classList.add("hidden");

                      // messaggio
                      if (
                        resultBoxWishlist &&
                        resultBoxWishlist.classList.contains("hidden")
                      ) {
                        resultBoxWishlist.classList.remove("hidden");
                      }

                      if (
                        resultBoxWishlistOk &&
                        resultBoxWishlistOk.classList.contains("hidden")
                      ) {
                        resultBoxWishlistOk.classList.remove("hidden");
                      }

                      if (resultBoxWishlistKo) {
                        resultBoxWishlistKo.classList.add("hidden");
                      }
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                }
              })
              .catch(function (error) {
                console.error(error);
              });
          });
        }

        // addToWishlist -> chiudi popup
        var addToWishlistClosePopup = document.querySelector(
          "#addToWishlistPopup .closePopup"
        );
        if (addToWishlistClosePopup) {
          addToWishlistClosePopup.addEventListener("click", function () {
            $.magnificPopup.close();
          });
        }
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },

    // Cart
    page_template_carrello: {
      init: function () {
        function sanitizeString(str) {
          str = str.toString().replace(/[^a-z0-9áéíóúñü? \.,_-]/gim, "");
          return str.trim();
        }

        var isCallingFunction = false;
        var timeout;

        // variabili condivise
        var baseUrlWP = document.querySelector("#baseUrlWP").value;
        var nonce = document.querySelector("#nonce").value;
        var idOrdine = document.querySelector("#GEMELS_ORDER_COOKIE").value;
        var codiceCliente = document.querySelector("#currentClientCode").value;
        var cartUserId = document.querySelector("#currentUserId").value;
        var cartUserName = document.querySelector("#currentUserName").value;
        var cartId = "cart_" + cartUserName;

        function debounce(func, wait, immediate) {
          isCallingFunction = true;
          return function () {
            var context = this,
              args = arguments;
            var later = function () {
              timeout = null;
              if (!immediate) {
                console.log("chiamo la funzione");
                func.apply(context, args);
              }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
              func.apply(context, args);
            }
          };
        }

        function updateCartTotal() {
          var cartTotalCount = 0;
          var unitPerQntAll = document.querySelectorAll(".unitPerQnt");
          if (unitPerQntAll) {
            unitPerQntAll.forEach(function (item) {
              var singleItemTotal = item.value;
              cartTotalCount += parseFloat(singleItemTotal);
            });
            var summary = document.querySelector(".summary span");
            var cartTotal = document.querySelector(".cartTotal");
            cartTotal.value = cartTotalCount.toFixed(2);
            summary.innerHTML =
              "€ " + cartTotalCount.toFixed(2).toString().replace(".", ",");
          }
        }

        function updateItemQty(indexRow, qty) {
          // se sto già effettuando una chiamata, non proseguo
          if (!isCallingFunction) {
            return;
          }

          axios({
            method: "get",
            url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
            headers: {
              "X-WP-Nonce": nonce,
            },
          })
            .then(function (response) {
              //console.log(response, response.status, response.data.length, response.data);

              if (200 === response.status) {
                isCallingFunction = false;

                // recupero id carrello
                var cartWPId = response.data[0].id;

                // recupero id utente
                var cartUserId =
                  response.data[0].acf.ecommerce_cart.ecommerce_cart_user;

                // elenco carrello
                var cartRows =
                  response.data[0].acf.ecommerce_cart.ecommerce_cart_rows;

                // modifico elenco carrello
                cartRows.forEach(function (item, index) {
                  if (index === parseInt(indexRow)) {
                    cartRows[index].ecommerce_cart_rows_qnt = parseInt(qty);
                  }
                });

                // preparo body
                var postData = {
                  title: cartId,
                  acf: {
                    ecommerce_cart: {
                      ecommerce_cart_user: parseInt(cartUserId.value),
                      ecommerce_cart_rows: cartRows,
                    },
                  },
                  status: "publish",
                };

                // aggiorno quantità della singola riga di carrello
                axios({
                  method: "put",
                  url: baseUrlWP + "wp/v2/cart/" + cartWPId,
                  data: postData,
                  headers: {
                    "Content-Type": "application/json",
                    "X-WP-Nonce": nonce,
                  },
                })
                  .then(function (response) {})
                  .catch(function (error) {
                    alert(error);
                  });
              }
            })
            .catch(function (error) {
              alert(error);
            });
        }

        function updateAllDates(newDate) {
          // se sto già effettuando una chiamata, non proseguo
          if (!isCallingFunction) {
            return;
          }

          axios({
            method: "get",
            url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
            headers: {
              "X-WP-Nonce": nonce,
            },
          })
            .then(function (response) {
              //console.log(response, response.status, response.data.length, response.data);

              if (200 === response.status) {
                isCallingFunction = false;

                // recupero id carrello
                var cartWPId = response.data[0].id;

                // recupero id utente
                var cartUserId =
                  response.data[0].acf.ecommerce_cart.ecommerce_cart_user;

                // elenco carrello
                var cartRows =
                  response.data[0].acf.ecommerce_cart.ecommerce_cart_rows;

                // modifico elenco carrello
                cartRows.forEach(function (item, index) {
                  cartRows[index].ecommerce_cart_rows_shippingDate = newDate;
                });

                // preparo body
                var postData = {
                  title: cartId,
                  acf: {
                    ecommerce_cart: {
                      ecommerce_cart_user: parseInt(cartUserId.value),
                      ecommerce_cart_rows: cartRows,
                    },
                  },
                  status: "publish",
                };

                axios({
                  method: "put",
                  url: baseUrlWP + "wp/v2/cart/" + cartWPId,
                  data: postData,
                  headers: {
                    "Content-Type": "application/json",
                    "X-WP-Nonce": nonce,
                  },
                })
                  .then(function (response) {})
                  .catch(function (error) {
                    alert(error);
                  });
              }
            })
            .catch(function (error) {
              alert(error);
            });
        }

        function updateSingleDate(indexRow, newDate) {
          // se sto già effettuando una chiamata, non proseguo
          if (!isCallingFunction) {
            return;
          }

          axios({
            method: "get",
            url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
            headers: {
              "X-WP-Nonce": nonce,
            },
          })
            .then(function (response) {
              //console.log(response, response.status, response.data.length, response.data);

              if (200 === response.status) {
                isCallingFunction = false;

                // recupero id carrello
                var cartWPId = response.data[0].id;

                // recupero id utente
                var cartUserId =
                  response.data[0].acf.ecommerce_cart.ecommerce_cart_user;

                // elenco carrello
                var cartRows =
                  response.data[0].acf.ecommerce_cart.ecommerce_cart_rows;

                // modifico elenco carrello
                cartRows.forEach(function (item, index) {
                  if (index === parseInt(indexRow)) {
                    cartRows[index].ecommerce_cart_rows_shippingDate = newDate;
                  }
                });

                // preparo body
                var postData = {
                  title: cartId,
                  acf: {
                    ecommerce_cart: {
                      ecommerce_cart_user: parseInt(cartUserId.value),
                      ecommerce_cart_rows: cartRows,
                    },
                  },
                  status: "publish",
                };

                axios({
                  method: "put",
                  url: baseUrlWP + "wp/v2/cart/" + cartWPId,
                  data: postData,
                  headers: {
                    "Content-Type": "application/json",
                    "X-WP-Nonce": nonce,
                  },
                })
                  .then(function (response) {})
                  .catch(function (error) {
                    alert(error);
                  });
              }
            })
            .catch(function (error) {
              alert(error);
            });
        }

        function updateOrderNote() {
          console.log("aggiorno note + numero ordine interno cliente");

          // se sto già effettuando una chiamata, non proseguo
          if (!isCallingFunction) {
            return;
          }

          // controllo prima se esiste già l'ordine
          axios({
            method: "get",
            url: baseUrlWP + "wp/v2/user-order/?search=" + idOrdine,
            headers: {
              "X-WP-Nonce": nonce,
            },
          })
            .then(function (response) {
              //console.log(response, response.status, response.data.length, response.data);

              if (200 === response.status) {
                var methodToCall = "";
                var urlToCall = "";

                // ho trovato l'ordine per l'utente, lo aggiorno con PUT method
                if (response.data.length > 0) {
                  methodToCall = "put";
                  urlToCall =
                    baseUrlWP + "wp/v2/user-order/" + response.data[0].id;
                } else {
                  //console.log('non ho trovato l'ordine');

                  // non ho trovato l'ordine, lo creo con POST method
                  methodToCall = "post";
                  urlToCall = baseUrlWP + "wp/v2/user-order";
                }

                //console.log(currentOrderRows);

                // recupero note ordine
                var noteOrdine = document.querySelector("#noteOrdine");

                // recupero numero interno ordine
                var numeroOrdineInterno = document.querySelector(
                  "#numeroOrdineInterno"
                );

                // preparo body ordine
                var postData = {
                  title: idOrdine,
                  acf: {
                    ecommerce_user_orders: {
                      ecommerce_user_orders_orderId: idOrdine,
                      ecommerce_user_orders_user: parseInt(cartUserId),
                      ecommerce_user_orders_codiceCliente: codiceCliente,
                      ecommerce_user_orders_internalNumber: sanitizeString(
                        numeroOrdineInterno.value
                      ),
                      ecommerce_user_orders_orderNotes: sanitizeString(
                        noteOrdine.value
                      ),
                    },
                  },
                  status: "publish",
                };

                // aggiungo il record
                axios({
                  method: methodToCall,
                  url: urlToCall,
                  data: postData,
                  headers: {
                    "Content-Type": "application/json",
                    "X-WP-Nonce": nonce,
                  },
                })
                  .then(function () {})
                  .catch(function (error) {
                    console.error(error);
                  });
              }
            })
            .catch(function (error) {
              console.error(error);
            });
        }

        /**
         * Aggiungi prodotto a carrello da carrello
         */
        var tableData = document.querySelector(
          "#addToCartPopup .content .table_content"
        );
        // nascondo riga "materiale" (non mi arriva da web service)
        var materiale = tableData.querySelector(".materiale");
        if (materiale) {
          materiale.remove();
        }

        // preloader
        var preloaderPopupAdd = document.querySelector(
          "#addToCartPopup .preloader"
        );

        // result
        var resultBox = document.querySelector(
          "#addToCartPopup .resultMessage"
        );
        var resultBoxKo = document.querySelector(
          "#addToCartPopup .resultMessage .error"
        );
        var resultBoxOk = document.querySelector(
          "#addToCartPopup .resultMessage .message"
        );
        var vaiAlCarrelloLInk = resultBox.querySelector("a");
        vaiAlCarrelloLInk.remove();

        // variabili condivise popup
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var currentDate = year + "-" + month + "-" + day;

        // input nascosti popup
        var unitPricePopup = document.querySelector("#unitPricePopup");
        var modelNamePopup = document.querySelector("#modelNamePopup");
        var modelDescPopup = document.querySelector("#modelDescPopup");
        var modelQtyPerParcelPopup = document.querySelector(
          "#modelQtyPerParcelPopup"
        );

        var buttonToRemove = document.querySelector(
          '.facetwp-type-autocomplete input[type="button"]'
        );
        if (buttonToRemove) {
          buttonToRemove.remove();
        }

        function openModalAddToCart() {
          var cercaPerCodice = document.querySelector(".facetwp-autocomplete");
          cercaPerCodice = sanitizeString(cercaPerCodice.value);

          if (cercaPerCodice !== "") {
            // nascondo tabella contenuto prima di chiamare il web service
            tableData.classList.add("hidden");

            if (
              preloaderPopupAdd &&
              preloaderPopupAdd.classList.contains("hidden")
            ) {
              preloaderPopupAdd.classList.remove("hidden");
            }

            $.magnificPopup.open({
              items: {
                src: "#addToCartPopup",
              },
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  // verifico su Wordpress se il modello è acquistabile (la ricerca è su LIKE, quindi trova anche le stringhe parziali, la chiamata successiva ad EOS per il prezzo verifica la correttezza del codice)
                  axios({
                    method: "get",
                    url: baseUrlWP + "wp/v2/model?search='" + cercaPerCodice,
                    headers: {
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      //console.log(response, response.status, response.data.length);
                      //console.log(response.status, response.data, response.data[0].acf.ecommerce_model.ecommerce_model_purchasable);

                      if (
                        200 === response.status &&
                        response.data &&
                        response.data[0].acf.ecommerce_model
                          .ecommerce_model_purchasable
                      ) {
                        var myModel = response.data[0];
                        //console.log(myModel);

                        // aggiorno campi nascosti per nome e descrizione
                        modelNamePopup.value = cercaPerCodice;
                        modelDescPopup.value =
                          myModel.acf.ecommerce_model.ecommerce_model_text;
                        modelQtyPerParcelPopup.value =
                          myModel.acf.ecommerce_model.ecommerce_model_qtyPerParcel;

                        // chiamata a Web Service EOS
                        // recupero prezzo corrente da EOS
                        axios({
                          method: "get",
                          url:
                            baseUrlWP +
                            "gemels/v1/search-model/" +
                            cercaPerCodice,
                          headers: {
                            "X-WP-Nonce": nonce,
                          },
                        })
                          .then(function (response) {
                            //console.log(response, response.status, response.data.length);

                            if (
                              200 === response.status &&
                              response.data.value !== undefined &&
                              response.data.value[0].itemNo === cercaPerCodice
                            ) {
                              // aggiorno campi nascosti per aggiungi a carrello
                              unitPricePopup.value =
                                response.data.value[0].price; // N.B Prezzo commentato temporaneamente 04/10/2023
                              //unitPricePopup.value = 0;

                              // nascondo preloader
                              preloaderPopupAdd.classList.add("hidden");

                              // popolo campi tabella
                              var modelNameTable = tableData.querySelector(
                                ".modelName"
                              );
                              modelNameTable.innerHTML = cercaPerCodice;
                              var modelDescTable = tableData.querySelector(
                                ".modelDesc"
                              );
                              modelDescTable.innerHTML = modelDescPopup.value;
                              var modelDNTable = tableData.querySelector(
                                ".modelDN"
                              );
                              modelDNTable.innerHTML = "DN" + myModel.acf.dn;
                              var modelPriceTable = tableData.querySelector(
                                ".modelPrice"
                              );
                              modelPriceTable.innerHTML =
                                "€ " +
                                unitPricePopup.value
                                  .toString()
                                  .replace(".", ","); //N.B Prezzo commentato temporaneamente 04/10/2023
                              //modelPriceTable.innerHTML = '€ 0';
                              var qtyTable = tableData.querySelector("#qty");
                              var stepQnt =
                                modelQtyPerParcelPopup.value === "0"
                                  ? 1
                                  : modelQtyPerParcelPopup.value;
                              qtyTable.value = stepQnt;

                              // eventi su + e -
                              var addQnt = document.querySelector(
                                "#addToCartPopup .modelQnt .piu"
                              );
                              var removeQnt = document.querySelector(
                                "#addToCartPopup .modelQnt .meno"
                              );
                              var oldQnt = parseInt(stepQnt);
                              var newQnt = parseInt(stepQnt);
                              if (addQnt && removeQnt) {
                                addQnt.addEventListener("click", function () {
                                  newQnt += oldQnt;
                                  qtyTable.value = newQnt;
                                });

                                removeQnt.addEventListener(
                                  "click",
                                  function () {
                                    if (newQnt - oldQnt >= 1) {
                                      newQnt -= oldQnt;
                                      qtyTable.value = newQnt;
                                    }
                                  }
                                );
                              }

                              // mostro tabella dati + aggiungi a carrello
                              tableData.classList.remove("hidden");
                            } else {
                              // messaggio articolo non trovato su EOS
                              if (
                                resultBox &&
                                resultBox.classList.contains("hidden")
                              ) {
                                resultBox.classList.remove("hidden");
                              }

                              if (
                                resultBoxKo &&
                                resultBoxKo.classList.contains("hidden")
                              ) {
                                resultBoxKo.classList.remove("hidden");
                              }

                              if (resultBoxOk) {
                                resultBoxOk.classList.add("hidden");
                              }

                              // nascondo preloader
                              preloaderPopupAdd.classList.add("hidden");
                            }
                          })
                          .catch(function (error) {
                            console.error(error);

                            // messaggio
                            if (
                              resultBox &&
                              resultBox.classList.contains("hidden")
                            ) {
                              resultBox.classList.remove("hidden");
                            }

                            if (
                              resultBoxKo &&
                              resultBoxKo.classList.contains("hidden")
                            ) {
                              resultBoxKo.classList.remove("hidden");
                            }

                            if (resultBoxOk) {
                              resultBoxOk.classList.add("hidden");
                            }

                            // nascondo preloader
                            preloaderPopupAdd.classList.add("hidden");
                          });
                      } else {
                        // messaggio
                        if (
                          resultBox &&
                          resultBox.classList.contains("hidden")
                        ) {
                          resultBox.classList.remove("hidden");
                        }

                        if (
                          resultBoxKo &&
                          resultBoxKo.classList.contains("hidden")
                        ) {
                          resultBoxKo.classList.remove("hidden");
                        }

                        if (resultBoxOk) {
                          resultBoxOk.classList.add("hidden");
                        }

                        // nascondo preloader
                        preloaderPopupAdd.classList.add("hidden");
                      }
                    })
                    .catch(function (error) {
                      console.log(error);

                      // messaggio
                      if (resultBox && resultBox.classList.contains("hidden")) {
                        resultBox.classList.remove("hidden");
                      }

                      if (
                        resultBoxKo &&
                        resultBoxKo.classList.contains("hidden")
                      ) {
                        resultBoxKo.classList.remove("hidden");
                      }

                      if (resultBoxOk) {
                        resultBoxOk.classList.add("hidden");
                      }

                      // nascondo preloader
                      preloaderPopupAdd.classList.add("hidden");
                    });
                },
                close: function () {
                  // resetto campi vari

                  // quantità
                  var currentQty = document.querySelector("#qty");
                  currentQty.value = 1;

                  modelDescPopup.value = "";
                  modelQtyPerParcelPopup.value = "";
                  tableData.classList.add("hidden");
                  preloaderPopupAdd.classList.remove("hidden");

                  // messaggio
                  resultBox.classList.add("hidden");

                  if (
                    resultBoxOk &&
                    !resultBoxOk.classList.contains("hidden")
                  ) {
                    resultBoxOk.classList.add("hidden");
                  }

                  if (
                    resultBoxKo &&
                    !resultBoxKo.classList.contains("hidden")
                  ) {
                    resultBoxKo.classList.add("hidden");
                  }
                },
              },
            });
          }
        }

        function openPopupQty() {
          var inputRicercaCodice = document.querySelector(
            ".ricercaCarrello .facetwp-autocomplete"
          );
          console.info(inputRicercaCodice.value);

          openModalAddToCart();
        }

        // eventi su + e -
        var stepQnt;
        var giacenza;
        var parentTd;
        var parentTr;
        var newQnt;
        var currentQty;
        var unitPrice;
        var unitPerQnt;
        var modelTotalPrice;
        var removeQntButtons = document.querySelectorAll("#cartTable .meno");
        // rimuovo quantità
        if (removeQntButtons) {
          removeQntButtons.forEach(function (item) {
            item.addEventListener("click", function (e) {
              parentTd = e.target.closest("td");
              stepQnt = parentTd.querySelector(".qntPerParcel").value;
              if (stepQnt === "0") {
                stepQnt = 1;
              }
              currentQty = parentTd.querySelector(".qnt");
              oldQnt = parseInt(currentQty.value);
              newQnt = parseInt(currentQty.value);
              parentTr = e.target.closest("tr");
              unitPrice = parentTr.querySelector(".unitPrice");
              unitPerQnt = parentTr.querySelector(".unitPerQnt");
              modelTotalPrice = parentTr.querySelector(".modelTotalPrice span");

              if (parseInt(currentQty.value) - parseInt(stepQnt) >= 1) {
                newQnt = parseInt(currentQty.value) - parseInt(stepQnt);
                currentQty.value = newQnt;

                unitPerQnt.value = (
                  parseFloat(unitPrice.value) * parseInt(currentQty.value)
                ).toFixed(2);
                modelTotalPrice.innerHTML =
                  "€ " + unitPerQnt.value.toString().replace(".", ",");
              }

              // verifico giacenza
              giacenza = parseInt(parentTd.querySelector(".giacenza").value);
              var blocco_giacenza = parentTd.querySelector(".blocco_giacenza");
              var tooltip_giacenza_green = parentTd.querySelector("span.green");
              var tooltip_giacenza_orange = parentTd.querySelector(
                "span.orange"
              );
              console.log(
                currentQty.value,
                giacenza,
                typeof currentQty.value,
                typeof giacenza
              );
              if (parseInt(currentQty.value) <= giacenza && giacenza > 0) {
                // disponibile
                if (blocco_giacenza) {
                  if (blocco_giacenza.classList.contains("orange")) {
                    blocco_giacenza.classList.remove("orange");
                  }

                  blocco_giacenza.classList.add("green");

                  if (tooltip_giacenza_orange.classList.contains("visible")) {
                    tooltip_giacenza_orange.classList.remove("visible");
                  }

                  tooltip_giacenza_green.classList.add("visible");
                }
              }

              if (parseInt(currentQty.value) > giacenza && giacenza > 0) {
                // parzialmente disponibile
                if (blocco_giacenza) {
                  if (blocco_giacenza.classList.contains("green")) {
                    blocco_giacenza.classList.remove("green");
                  }

                  blocco_giacenza.classList.add("orange");

                  if (tooltip_giacenza_green.classList.contains("visible")) {
                    tooltip_giacenza_green.classList.remove("visible");
                  }

                  tooltip_giacenza_orange.classList.add("visible");
                }
              }

              // aggiorno totale
              updateCartTotal();

              // aggiorno database
              var indexRow = parentTr.id.split("_")[2];

              debounce(function () {
                return updateItemQty(indexRow, currentQty.value);
              }, 500)();
            });
          });
        }

        // aggiungo quantità
        var addQntButtons = document.querySelectorAll("#cartTable .piu");
        if (addQntButtons) {
          addQntButtons.forEach(function (item) {
            item.addEventListener("click", function (e) {
              parentTd = e.target.closest("td");
              stepQnt = parentTd.querySelector(".qntPerParcel").value;
              if (stepQnt === "0") {
                stepQnt = 1;
              }
              currentQty = parentTd.querySelector(".qnt");
              oldQnt = parseInt(currentQty.value);
              newQnt = parseInt(currentQty.value);
              parentTr = e.target.closest("tr");
              unitPrice = parentTr.querySelector(".unitPrice");
              unitPerQnt = parentTr.querySelector(".unitPerQnt");
              modelTotalPrice = parentTr.querySelector(".modelTotalPrice span");

              newQnt = parseInt(currentQty.value) + parseInt(stepQnt);
              currentQty.value = newQnt;

              unitPerQnt.value = (
                parseFloat(unitPrice.value) * parseInt(currentQty.value)
              ).toFixed(2);
              modelTotalPrice.innerHTML =
                "€ " + unitPerQnt.value.toString().replace(".", ",");

              // verifico giacenza
              giacenza = parseInt(parentTd.querySelector(".giacenza").value);
              var blocco_giacenza = parentTd.querySelector(".blocco_giacenza");
              var tooltip_giacenza_green = parentTd.querySelector("span.green");
              var tooltip_giacenza_orange = parentTd.querySelector(
                "span.orange"
              );
              if (parseInt(currentQty.value) <= giacenza && giacenza > 0) {
                // disponibile
                if (blocco_giacenza) {
                  if (blocco_giacenza.classList.contains("orange")) {
                    blocco_giacenza.classList.remove("orange");
                  }

                  blocco_giacenza.classList.add("green");

                  if (tooltip_giacenza_orange.classList.contains("visible")) {
                    tooltip_giacenza_orange.classList.remove("visible");
                  }

                  tooltip_giacenza_green.classList.add("visible");
                }
              }

              if (parseInt(currentQty.value) > giacenza && giacenza > 0) {
                // parzialmente disponibile
                if (blocco_giacenza) {
                  if (blocco_giacenza.classList.contains("green")) {
                    blocco_giacenza.classList.remove("green");
                  }

                  blocco_giacenza.classList.add("orange");

                  if (tooltip_giacenza_green.classList.contains("visible")) {
                    tooltip_giacenza_green.classList.remove("visible");
                  }

                  tooltip_giacenza_orange.classList.add("visible");
                }
              }

              // aggiorno totale
              updateCartTotal();

              // aggiorno database
              var indexRow = parentTr.id.split("_")[2];

              debounce(function () {
                return updateItemQty(indexRow, currentQty.value);
              }, 500)();
            });
          });
        }

        // Aggiorna tutte le date del carrello
        var aggiornaDate = document.querySelector("#aggiornaDate");
        if (aggiornaDate) {
          aggiornaDate.addEventListener("click", function (e) {
            // recupero data
            var shippingDateAll = document.querySelector("#shippingDateAll")
              .value;
            if (shippingDateAll !== "") {
              var shippingDate = document.querySelectorAll(
                'input[type="hidden"].shippingDate'
              );
              if (shippingDate) {
                shippingDate.forEach(function (item) {
                  // recupero instance di flatpickr
                  var fp = item._flatpickr;
                  fp.setDate(shippingDateAll);
                });
              }

              // aggiorno anche il DB
              debounce(function () {
                return updateAllDates(shippingDateAll);
              }, 500)();
            }
          });
        }

        // popup rimuovi articolo da carrello
        var removeFromCartList = document.querySelectorAll(".removeFromCart");
        if (removeFromCartList) {
          // elementi in comune
          var preloaderPopup = document.querySelector(
            "#removeFromCartPopup .preloader"
          );
          var resultMessage = document.querySelector(
            "#removeFromCartPopup .resultMessage"
          );
          var resultOk = resultMessage.querySelector(".message");
          var resultKo = resultMessage.querySelector(".error");

          removeFromCartList.forEach(function (item) {
            var thisElement = $(item);

            thisElement.magnificPopup({
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  parentTr = item.closest("tr");
                  var rowIdElement = parentTr.id;
                  var rowId = rowIdElement.split("_")[2];
                  var modelName = parentTr
                    .querySelector(".modelName")
                    .innerHTML.trimStart()
                    .trimEnd();
                  var ecommerceModelName = document.querySelector(
                    "#ecommerceModelName"
                  );
                  ecommerceModelName.innerHTML = modelName;

                  // aggiorno campo nascosto modelRowId
                  var modelRowId = document.querySelector(
                    "#removeFromCartPopup #modelRowId"
                  );
                  modelRowId.value = rowId;

                  // recupero il carrello
                  var cartUserName = document.querySelector("#currentUserName")
                    .value;
                  var cartId = "cart_" + cartUserName;

                  // nonce
                  var nonce = document.querySelector("#nonce").value;

                  // mostro il preloader
                  if (
                    preloaderPopup &&
                    preloaderPopup.classList.contains("hidden")
                  ) {
                    preloaderPopup.classList.remove("hidden");
                  }

                  // baseUrl WP
                  var baseUrlWP = document.querySelector("#baseUrlWP").value;

                  axios({
                    method: "get",
                    url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
                    headers: {
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      //console.log(response, response.status, response.data.length, response.data);

                      // nascondo preloader
                      preloaderPopup.classList.add("hidden");

                      if (200 === response.status) {
                        // mostro pulsante per rimuovere
                        var content = document.querySelector(
                          "#removeFromCartPopup .content"
                        );
                        content.classList.remove("hidden");

                        // aggiorno id interno del carrello (wordpress)
                        var cartWPId = document.querySelector(
                          "#removeFromCartPopup #cartWPId"
                        );
                        cartWPId.value = response.data[0].id;

                        // metto json serializzato del carrello in campo nascosto
                        var cartRows = document.querySelector(
                          "#removeFromCartPopup #cartRows"
                        );
                        cartRows.value = JSON.stringify(
                          response.data[0].acf.ecommerce_cart
                        );
                      } else {
                        resultMessage.classList.remove("hidden");
                        resultKo.classList.remove("hidden");
                        if (
                          resultOk &&
                          !resultOk.classList.contains("hidden")
                        ) {
                          resultOk.classList.add("hidden");
                        }
                      }
                    })
                    .catch(function (error) {
                      console.error(error);

                      resultMessage.classList.remove("hidden");
                      resultKo.classList.remove("hidden");
                      if (resultOk && !resultOk.classList.contains("hidden")) {
                        resultOk.classList.add("hidden");
                      }
                    });
                },
                close: function () {
                  // reset vari

                  preloaderPopup.classList.remove("hidden");

                  // messaggio
                  resultMessage.classList.add("hidden");

                  if (resultOk && !resultOk.classList.contains("hidden")) {
                    resultOk.classList.add("hidden");
                  }

                  if (resultKo && !resultKo.classList.contains("hidden")) {
                    resultKo.classList.add("hidden");
                  }
                },
              },
            });
          });
        }

        // pulsante rimuovi
        var removeArticleButton = document.querySelector(
          "#removeFromCartPopup .bottom button"
        );
        if (removeArticleButton) {
          removeArticleButton.addEventListener("click", function (e) {
            e.stopPropagation();

            // recupero il carrello
            var cartUserName = document.querySelector("#currentUserName").value;
            var cartId = "cart_" + cartUserName;

            // nonce
            var nonce = document.querySelector("#nonce").value;

            // nascondo content
            var content = document.querySelector(
              "#removeFromCartPopup .content"
            );
            content.classList.add("hidden");

            // mostro il preloader
            var preloaderPopup = document.querySelector(
              "#removeFromCartPopup .preloader"
            );
            if (preloaderPopup && preloaderPopup.classList.contains("hidden")) {
              preloaderPopup.classList.remove("hidden");
            }

            // recupero json carrello e id della riga da rimuovere
            var modelRowId = document.querySelector(
              "#removeFromCartPopup #modelRowId"
            );
            var cartRows = document.querySelector(
              "#removeFromCartPopup #cartRows"
            );
            // deserializzo json
            var cartObj = JSON.parse(cartRows.value);
            //console.log(cartObj);

            // rimuovo modelRowId
            var newCartRows = cartObj.ecommerce_cart_rows;
            var removedRowElement;
            var myRowIndex = parseInt(modelRowId.value);
            var indexRows = newCartRows.map(function (e, index) {
              return index === myRowIndex;
            });
            if (indexRows[myRowIndex] === true) {
              removedRowElement = newCartRows.splice(myRowIndex, 1);
            }

            // preparo body
            cartUserId = cartObj.ecommerce_cart_user;
            var postData = {
              title: cartId,
              acf: {
                ecommerce_cart: {
                  ecommerce_cart_user: parseInt(cartUserId),
                  ecommerce_cart_rows: newCartRows,
                },
              },
              status: "publish",
            };

            // baseUrl WP
            var baseUrlWP = document.querySelector("#baseUrlWP").value;

            // id interno carrello (wordpress)
            var cartWPId = document.querySelector(
              "#removeFromCartPopup #cartWPId"
            );

            // messaggi d'errore o ok
            var resultMessage = document.querySelector(
              "#removeFromCartPopup .resultMessage"
            );
            var resultOk = resultMessage.querySelector(".message");
            var resultKo = resultMessage.querySelector(".error");

            // aggiorno il carrello
            axios({
              method: "put",
              url: baseUrlWP + "wp/v2/cart/" + cartWPId.value,
              data: postData,
              headers: {
                "Content-Type": "application/json",
                "X-WP-Nonce": nonce,
              },
            })
              .then(function (response) {
                // nascondo preloader
                preloaderPopup.classList.add("hidden");

                //console.log(response, response.status, response.data.length, response.data);

                if (200 === response.status) {
                  // se era l'ultimo record ricarico la pagina
                  var tableTrCount = document.querySelectorAll(".cartElement");
                  if (tableTrCount.length === 1) {
                    location.reload();
                    return;
                  }

                  // tolgo totale riga da totale carrello
                  var rowToRemove = document.querySelector(
                    "#cart_item_" + parseInt(modelRowId.value)
                  );
                  console.log(rowToRemove);

                  var rowTotalToRemove = rowToRemove.querySelector(
                    ".modelTotalPrice .unitPerQnt"
                  ).value;
                  var parentTbody = rowToRemove.closest("tbody");
                  var cartTotal = parentTbody.querySelector(
                    ".summary .cartTotal"
                  );
                  var cartTotalHtml = document.querySelector("#cartTotalHtml");
                  cartTotal.value = (
                    parseFloat(cartTotal.value) - parseFloat(rowTotalToRemove)
                  ).toFixed(2);
                  cartTotalHtml.innerHTML =
                    "€ " + cartTotal.value.toString().replace(".", ",");

                  //console.log(rowToRemove, rowTotalToRemove, parentTbody, cartTotal, cartTotalHtml);

                  // rimuovo la riga dal dom
                  rowToRemove.remove();

                  // messaggio
                  if (
                    resultMessage &&
                    resultMessage.classList.contains("hidden")
                  ) {
                    resultMessage.classList.remove("hidden");
                  }

                  if (resultOk && resultOk.classList.contains("hidden")) {
                    resultOk.classList.remove("hidden");
                  }

                  if (resultKo) {
                    resultKo.classList.add("hidden");
                  }

                  // ricarico la pagina
                  setTimeout(function () {
                    location.reload();
                    return;
                  }, 1000);
                } else {
                  resultMessage.classList.remove("hidden");
                  resultKo.classList.remove("hidden");
                  if (resultOk && !resultOk.classList.contains("hidden")) {
                    resultOk.classList.add("hidden");
                  }
                }
              })
              .catch(function (error) {
                console.error(error);

                resultMessage.classList.remove("hidden");
                resultKo.classList.remove("hidden");
                if (resultOk && !resultOk.classList.contains("hidden")) {
                  resultOk.classList.add("hidden");
                }
              });
          });
        }

        // removeFromCart -> chiudi popup
        var removeFromCartClosePopup = document.querySelector(
          "#removeFromCartPopup .closePopup"
        );
        if (removeFromCartClosePopup) {
          removeFromCartClosePopup.addEventListener("click", function () {
            $.magnificPopup.close();
          });
        }

        // listener su pulsante annulla ordine (da pagina)
        var deleteOrderButton = document.querySelector(".removeAllFromCart");
        if (deleteOrderButton) {
          // elementi in comune
          var preloaderDeleteOrderPopup = document.querySelector(
            "#removeAllFromCartPopup .preloader"
          );
          var resultDeleteOrderMessage = document.querySelector(
            "#removeAllFromCartPopup .resultMessage"
          );
          var resultDeleteOrderOk = resultDeleteOrderMessage.querySelector(
            ".message"
          );
          var resultDeleteOrderKo = resultDeleteOrderMessage.querySelector(
            ".error"
          );

          var thisElement = $(deleteOrderButton);

          thisElement.magnificPopup({
            type: "inline",
            midClick: true,
            mainClass: "popupGenerico",
            closeBtnInside: false,
            closeOnBgClick: false,
            callbacks: {
              open: function (e) {
                // recupero il token
                var cartUserName = document.querySelector("#currentUserName")
                  .value;
                var cartId = "cart_" + cartUserName;

                // nonce
                var nonce = document.querySelector("#nonce").value;

                // mostro il preloader
                if (
                  preloaderDeleteOrderPopup &&
                  preloaderDeleteOrderPopup.classList.contains("hidden")
                ) {
                  preloaderDeleteOrderPopup.classList.remove("hidden");
                }

                // baseUrl WP
                var baseUrlWP = document.querySelector("#baseUrlWP").value;

                axios({
                  method: "get",
                  url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
                  headers: {
                    "X-WP-Nonce": nonce,
                  },
                })
                  .then(function (response) {
                    //console.log(response, response.status, response.data.length, response.data);

                    // nascondo preloader
                    preloaderDeleteOrderPopup.classList.add("hidden");

                    if (200 === response.status) {
                      // mostro pulsante per rimuovere
                      var content = document.querySelector(
                        "#removeAllFromCartPopup .content"
                      );
                      content.classList.remove("hidden");

                      // aggiorno id interno del carrello (wordpress)
                      var cartWPId = document.querySelector(
                        "#removeAllFromCartPopup #cartWPId"
                      );
                      cartWPId.value = response.data[0].id;

                      // aggiorno userId
                      var cartUserId = document.querySelector(
                        "#removeAllFromCartPopup #cartUserId"
                      );
                      cartUserId.value =
                        response.data[0].acf.ecommerce_cart.ecommerce_cart_user;
                    } else {
                      resultDeleteOrderMessage.classList.remove("hidden");
                      resultDeleteOrderKo.classList.remove("hidden");
                      if (
                        resultDeleteOrderOk &&
                        !resultDeleteOrderOk.classList.contains("hidden")
                      ) {
                        resultDeleteOrderOk.classList.add("hidden");
                      }
                    }
                  })
                  .catch(function (error) {
                    console.error(error);

                    resultDeleteOrderMessage.classList.remove("hidden");
                    resultDeleteOrderKo.classList.remove("hidden");
                    if (
                      resultDeleteOrderOk &&
                      !resultDeleteOrderOk.classList.contains("hidden")
                    ) {
                      resultDeleteOrderOk.classList.add("hidden");
                    }
                  });
              },
              close: function () {
                resultDeleteOrderMessage.classList.add("hidden");
                resultDeleteOrderKo.classList.add("hidden");
                resultDeleteOrderOk.classList.add("hidden");
              },
            },
          });
        }

        // pulsante annulla ordine (all'interno del popup)
        var removeAllArticlesButton = document.querySelector(
          "#removeAllFromCartPopup .bottom button"
        );
        if (removeAllArticlesButton) {
          removeAllArticlesButton.addEventListener("click", function (e) {
            e.stopPropagation();

            // recupero il carrello
            var cartUserName = document.querySelector("#currentUserName").value;
            var cartId = "cart_" + cartUserName;

            // nonce
            var nonce = document.querySelector("#nonce").value;

            // nascondo content
            var content = document.querySelector(
              "#removeAllFromCartPopup .content"
            );
            content.classList.add("hidden");

            // mostro il preloader
            var preloaderPopup = document.querySelector(
              "#removeAllFromCartPopup .preloader"
            );
            if (preloaderPopup && preloaderPopup.classList.contains("hidden")) {
              preloaderPopup.classList.remove("hidden");
            }

            // baseUrl WP
            var baseUrlWP = document.querySelector("#baseUrlWP").value;

            // id interno carrello (wordpress)
            var cartWPId = document.querySelector(
              "#removeAllFromCartPopup #cartWPId"
            );

            // messaggi d'errore o ok
            var resultMessage = document.querySelector(
              "#removeAllFromCartPopup .resultMessage"
            );
            var resultOk = resultMessage.querySelector(".message");
            var resultKo = resultMessage.querySelector(".error");

            // preparo body
            var cartUserId = document.querySelector(
              "#removeAllFromCartPopup #cartUserId"
            );
            var postData = {
              title: cartId,
              acf: {
                ecommerce_cart: {
                  ecommerce_cart_user: parseInt(cartUserId.value),
                  ecommerce_cart_rows: [],
                },
              },
              status: "publish",
            };

            // svuoto il carrello
            axios({
              method: "put",
              url: baseUrlWP + "wp/v2/cart/" + cartWPId.value,
              data: postData,
              headers: {
                "Content-Type": "application/json",
                "X-WP-Nonce": nonce,
              },
            })
              .then(function (response) {
                //console.log(response, response.status, response.data.length, response.data);

                if (200 === response.status) {
                  // cancello ordine
                  var orderId = document.querySelector("#GEMELS_ORDER_COOKIE")
                    .value;

                  // ricerco ordine corrente
                  axios({
                    method: "get",
                    url: baseUrlWP + "wp/v2/user-order/?search=" + orderId,
                    headers: {
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      //console.log(response, response.status, response.data.length, response.data);

                      if (200 === response.status) {
                        // recupero id ordine da cancellare
                        var userOrderWPId = response.data[0];

                        // se ho trovato un ordine associato al carrello corrente, lo cancello
                        if (userOrderWPId) {
                          axios({
                            method: "delete",
                            url:
                              baseUrlWP +
                              "wp/v2/user-order/" +
                              userOrderWPId.id,
                            headers: {
                              "X-WP-Nonce": nonce,
                            },
                          })
                            .then(function (response) {
                              if (200 === response.status) {
                                // resetto il cookie session
                                axios({
                                  method: "get",
                                  url: baseUrlWP + "gemels/v1/reset-cookie",
                                  headers: {
                                    "X-WP-Nonce": nonce,
                                  },
                                })
                                  .then(function () {
                                    // nascondo preloader
                                    preloaderPopup.classList.add("hidden");

                                    // messaggio
                                    if (
                                      resultMessage &&
                                      resultMessage.classList.contains("hidden")
                                    ) {
                                      resultMessage.classList.remove("hidden");
                                    }

                                    if (
                                      resultOk &&
                                      resultOk.classList.contains("hidden")
                                    ) {
                                      resultOk.classList.remove("hidden");
                                    }

                                    if (resultKo) {
                                      resultKo.classList.add("hidden");
                                    }

                                    setTimeout(function () {
                                      // carrello vuoto, ricarico pagina dopo 1 secondo
                                      location.reload();
                                    }, 1000);
                                  })
                                  .catch(function (error) {
                                    console.error(error);

                                    resultMessage.classList.remove("hidden");
                                    resultKo.classList.remove("hidden");
                                    if (
                                      resultOk &&
                                      !resultOk.classList.contains("hidden")
                                    ) {
                                      resultOk.classList.add("hidden");
                                    }
                                  });
                              } else {
                                resultMessage.classList.remove("hidden");
                                resultKo.classList.remove("hidden");
                                if (
                                  resultOk &&
                                  !resultOk.classList.contains("hidden")
                                ) {
                                  resultOk.classList.add("hidden");
                                }
                              }
                            })
                            .catch(function (error) {
                              console.error(error);

                              resultMessage.classList.remove("hidden");
                              resultKo.classList.remove("hidden");
                              if (
                                resultOk &&
                                !resultOk.classList.contains("hidden")
                              ) {
                                resultOk.classList.add("hidden");
                              }
                            });
                        } else {
                          // l'ordine non è ancora stato creato, quindi ricarico solamente la pagina
                          // nascondo preloader
                          preloaderPopup.classList.add("hidden");

                          // messaggio
                          if (
                            resultMessage &&
                            resultMessage.classList.contains("hidden")
                          ) {
                            resultMessage.classList.remove("hidden");
                          }

                          if (
                            resultOk &&
                            resultOk.classList.contains("hidden")
                          ) {
                            resultOk.classList.remove("hidden");
                          }

                          if (resultKo) {
                            resultKo.classList.add("hidden");
                          }

                          setTimeout(function () {
                            // carrello vuoto, ricarico pagina dopo 1 secondo
                            location.reload();
                          }, 1000);
                        }
                      } else {
                        resultMessage.classList.remove("hidden");
                        resultKo.classList.remove("hidden");
                        if (
                          resultOk &&
                          !resultOk.classList.contains("hidden")
                        ) {
                          resultOk.classList.add("hidden");
                        }
                      }
                    })
                    .catch(function (error) {
                      console.error(error);

                      resultMessage.classList.remove("hidden");
                      resultKo.classList.remove("hidden");
                      if (resultOk && !resultOk.classList.contains("hidden")) {
                        resultOk.classList.add("hidden");
                      }
                    });

                  // fine cancellazione ordine
                } else {
                  resultMessage.classList.remove("hidden");
                  resultKo.classList.remove("hidden");
                  if (resultOk && !resultOk.classList.contains("hidden")) {
                    resultOk.classList.add("hidden");
                  }
                }
              })
              .catch(function (error) {
                console.error(error);

                resultMessage.classList.remove("hidden");
                resultKo.classList.remove("hidden");
                if (resultOk && !resultOk.classList.contains("hidden")) {
                  resultOk.classList.add("hidden");
                }
              });
          });
        }

        // removeAllFromCart -> chiudi popup
        var removeAllFromCartClosePopup = document.querySelector(
          "#removeAllFromCartPopup .closePopup"
        );
        if (removeAllFromCartClosePopup) {
          removeAllFromCartClosePopup.addEventListener("click", function () {
            $.magnificPopup.close();
          });
        }

        var addToCart = document.querySelector(".addToCart");
        if (addToCart) {
          addToCart.addEventListener("click", function (e) {
            openModalAddToCart();
          });
        }

        // funzione aggiungi a carrello
        // aggiungo evento su pulsante "aggiungi a carrello"
        var ecommerceModelAddToCart = document.querySelector(
          "#addToCartPopup .bottom button"
        );
        if (ecommerceModelAddToCart) {
          ecommerceModelAddToCart.addEventListener("click", function (e) {
            e.stopPropagation();

            // nascondo tabella dati e mostro preloader
            tableData.classList.add("hidden");
            preloaderPopupAdd.classList.remove("hidden");

            // aggiungo il record al carrello
            var cartUserId = document.querySelector("#currentUserId").value;
            var cartUserName = document.querySelector("#currentUserName").value;
            var cartUnitPrice = unitPricePopup.value;
            var cartModelName = modelNamePopup.value;
            var cartModelDesc = modelDescPopup.value;
            var cartQtyPerParcel = modelQtyPerParcelPopup.value;

            // quantità
            var currentQty = document.querySelector("#qty");
            var cartQty = currentQty.value;

            var cartId = "cart_" + cartUserName;

            // controllo prima se esiste già un carrello
            axios({
              method: "get",
              url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
              headers: {
                "X-WP-Nonce": nonce,
              },
            })
              .then(function (response) {
                console.log(
                  response,
                  response.status,
                  response.data.length,
                  response.data
                );

                if (200 === response.status) {
                  var methodToCall = "";
                  var urlToCall = "";
                  var currentCartRows = [];
                  var oldCartRows = [];

                  var newItem = {
                    ecommerce_cart_rows_model: cartModelName,
                    ecommerce_cart_rows_text: cartModelDesc,
                    ecommerce_cart_rows_unitPrice: cartUnitPrice,
                    ecommerce_cart_rows_qnt: parseInt(cartQty),
                    ecommerce_cart_rows_qtyPerParcel: parseInt(
                      cartQtyPerParcel
                    ),
                  };

                  // ho trovato già un carrello per l'utente, lo aggiorno con PUT method
                  if (response.data.length > 0) {
                    console.log("ho già un carrello");
                    methodToCall = "put";
                    urlToCall = baseUrlWP + "wp/v2/cart/" + response.data[0].id;

                    // recupero l'elenco di prodotti già inseriti a carrello ed aggiungo il nuovo in testata
                    oldCartRows =
                      response.data[0].acf.ecommerce_cart.ecommerce_cart_rows;

                    // se il carrello era vuoto, faccio push, altrimenti unshift
                    if (oldCartRows && oldCartRows.length > 0) {
                      currentCartRows = oldCartRows;
                      currentCartRows.unshift(newItem);
                    } else {
                      currentCartRows.push(newItem);
                    }
                  } else {
                    console.log("non ho un carrello");
                    // non ho trovato un carrello, lo creo con POST method
                    methodToCall = "post";
                    urlToCall = baseUrlWP + "wp/v2/cart";

                    currentCartRows.push(newItem);
                  }

                  var postData = {
                    title: cartId,
                    acf: {
                      ecommerce_cart: {
                        ecommerce_cart_user: parseInt(cartUserId),
                        ecommerce_cart_rows: currentCartRows,
                      },
                    },
                    status: "publish",
                  };

                  //console.log(currentCartRows);

                  // aggiungo il record
                  axios({
                    method: methodToCall,
                    url: urlToCall,
                    data: postData,
                    headers: {
                      "Content-Type": "application/json",
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      // nascondo preloader
                      preloaderPopupAdd.classList.add("hidden");

                      // messaggio
                      if (resultBox && resultBox.classList.contains("hidden")) {
                        resultBox.classList.remove("hidden");
                      }

                      if (
                        resultBoxOk &&
                        resultBoxOk.classList.contains("hidden")
                      ) {
                        resultBoxOk.classList.remove("hidden");
                      }

                      if (resultBoxKo) {
                        resultBoxKo.classList.add("hidden");
                      }

                      // ricarico la pagina dopo tot secondi
                      setTimeout(function () {
                        location.reload();
                      }, 1000);
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                }
              })
              .catch(function (error) {
                console.error(error);
              });
          });
        }

        // addToCart -> chiudi popup
        var addToCartClosePopup = document.querySelector(
          "#addToCartPopup .closePopup"
        );
        if (addToCartClosePopup) {
          addToCartClosePopup.addEventListener("click", function () {
            $.magnificPopup.close();
          });
        }

        // messaggio checkout disabilitato (prodotti a carrello non più disponibili)
        var checkoutDisabled = document.querySelector("#cartDisabled");
        if (checkoutDisabled) {
          checkoutDisabled.addEventListener("click", function () {
            // mostro popup
            $.magnificPopup.open({
              items: {
                src: "#checkoutDisabled",
              },
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  setTimeout(function () {
                    $.magnificPopup.close();
                  }, 2000);
                },
                close: function () {},
              },
            });
          });
        }

        // aggiorno note ordine sul evento "input" del campo #noteOrdine
        var noteOrdine = document.querySelector("#noteOrdine");
        if (noteOrdine) {
          noteOrdine.addEventListener("input", function (e) {
            debounce(function () {
              return updateOrderNote();
            }, 500)();
          });
        }

        // aggiorno numero interno cliente sul evento "input" del campo #numeroOrdineInterno
        var numeroOrdineInterno = document.querySelector(
          "#numeroOrdineInterno"
        );
        if (numeroOrdineInterno) {
          numeroOrdineInterno.addEventListener("input", function (e) {
            debounce(function () {
              return updateOrderNote();
            }, 500)();
          });
        }

        // apro il popup della qta automaticamente dopo aver incollato o selezionato un codice articolo, senza dover cliccare su "CERCA"
        var divRicercaCodice = document.querySelector(
          ".ricercaCarrello .facetwp-facet"
        );
        // apro il popup della qta automaticamente dopo aver incollato o selezionato un codice articolo, senza dover cliccare su "CERCA"
        var mutationObserver = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            //console.log(mutation, typeof mutation.target, mutation.oldValue.toString().includes('is-loading'));
            if (!mutation.oldValue.includes("is-loading")) {
              debounce(function () {
                return openPopupQty();
              }, 100)();
            }
          });
        });

        mutationObserver.observe(divRicercaCodice, {
          attributes: true,
          characterData: false,
          childList: false,
          subtree: false,
          attributeOldValue: true,
          characterDataOldValue: false,
        });

        /**
         * CHECKOUT (da carrello a spedizione)
         */

        var checkout = document.querySelector("#checkout");
        if (checkout) {
          // preloader Checkout
          var preloaderPopupCheckout = document.querySelector(
            "#checkoutPopup .preloader"
          );

          // result
          var resultCheckoutBox = document.querySelector(
            "#checkoutPopup .resultMessage"
          );
          var resultCheckoutBoxKo = document.querySelector(
            "#checkoutPopup .resultMessage .error"
          );
          var resultCheckoutBoxOk = document.querySelector(
            "#checkoutPopup .resultMessage .message"
          );

          checkout.addEventListener("click", function () {
            // mostro preloader
            if (
              preloaderPopupCheckout &&
              preloaderPopupCheckout.classList.contains("hidden")
            ) {
              preloaderPopupCheckout.classList.remove("hidden");
            }

            // mostro popup
            $.magnificPopup.open({
              items: {
                src: "#checkoutPopup",
              },
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  // recupero note ordine
                  var noteOrdine = document.querySelector("#noteOrdine");

                  // recupero numero interno ordine
                  var numeroOrdineInterno = document.querySelector(
                    "#numeroOrdineInterno"
                  );

                  // recupero carrello
                  var cartOrderArray = [];
                  var cartArray = [];
                  var singleCartOrderRow;
                  var singleCartRow;
                  var cartRowElements = document.querySelectorAll(
                    ".cartElement"
                  );
                  if (cartRowElements) {
                    cartRowElements.forEach(function (item) {
                      var itemModel = item.querySelector(".modelName");
                      var itemText = item.querySelector(".modelDesc");
                      var itemUnitPrice = item.querySelector(".unitPrice");
                      var itemQty = item.querySelector(".qnt");
                      var itemShippingDate = item.querySelector(
                        'input[type="hidden"].shippingDate'
                      );
                      var itemQtyPerParcel = item.querySelector(
                        ".qntPerParcel"
                      );

                      // riga carrello per CTP user-order
                      singleCartOrderRow = {
                        ecommerce_user_orders_cart_model: itemModel.innerHTML.trim(),
                        ecommerce_user_orders_cart_text: itemText.innerHTML.trim(),
                        ecommerce_user_orders_cart_unitPrice: parseFloat(
                          itemUnitPrice.value
                        ),
                        ecommerce_user_orders_cart_qnt: parseInt(itemQty.value),
                        ecommerce_user_orders_cart_shippingDate:
                          itemShippingDate.value,
                        ecommerce_user_orders_cart_qtyPerParcel: parseInt(
                          itemQtyPerParcel.value
                        ),
                      };

                      cartOrderArray.push(singleCartOrderRow);

                      // riga carrello per CTP cart
                      singleCartRow = {
                        ecommerce_cart_rows_model: itemModel.innerHTML.trim(),
                        ecommerce_cart_rows_text: itemText.innerHTML.trim(),
                        ecommerce_cart_rows_unitPrice: parseFloat(
                          itemUnitPrice.value
                        ),
                        ecommerce_cart_rows_qnt: parseInt(itemQty.value),
                        ecommerce_cart_rows_shippingDate:
                          itemShippingDate.value,
                        ecommerce_cart_rows_qtyPerParcel: parseInt(
                          itemQtyPerParcel.value
                        ),
                      };

                      cartArray.push(singleCartRow);
                    });

                    //console.log(cartOrderArray);
                  }

                  // controllo prima se esiste già l'ordine
                  axios({
                    method: "get",
                    url: baseUrlWP + "wp/v2/user-order/?search=" + idOrdine,
                    headers: {
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      //console.log(response, response.status, response.data.length, response.data);

                      if (200 === response.status) {
                        var methodToCall = "";
                        var urlToCall = "";

                        // ho trovato l'ordine per l'utente, lo aggiorno con PUT method
                        if (response.data.length > 0) {
                          methodToCall = "put";
                          urlToCall =
                            baseUrlWP +
                            "wp/v2/user-order/" +
                            response.data[0].id;
                        } else {
                          //console.log('non ho trovato l'ordine');

                          // non ho trovato l'ordine, lo creo con POST method
                          methodToCall = "post";
                          urlToCall = baseUrlWP + "wp/v2/user-order";
                        }

                        //console.log(currentOrderRows);

                        // preparo body ordine
                        var postData = {
                          title: idOrdine,
                          acf: {
                            ecommerce_user_orders: {
                              ecommerce_user_orders_orderId: idOrdine,
                              ecommerce_user_orders_user: parseInt(cartUserId),
                              ecommerce_user_orders_codiceCliente: codiceCliente,
                              ecommerce_user_orders_internalNumber: sanitizeString(
                                numeroOrdineInterno.value
                              ),
                              ecommerce_user_orders_shippingAddress: "",
                              ecommerce_user_orders_creationDate: new Date(),
                              ecommerce_user_orders_sendDate: "",
                              ecommerce_user_orders_orderNotes: sanitizeString(
                                noteOrdine.value
                              ),
                              ecommerce_user_orders_orderState: "Bozza ordine",
                              ecommerce_user_orders_cart: cartOrderArray,
                            },
                          },
                          status: "publish",
                        };

                        // aggiungo il record
                        axios({
                          method: methodToCall,
                          url: urlToCall,
                          data: postData,
                          headers: {
                            "Content-Type": "application/json",
                            "X-WP-Nonce": nonce,
                          },
                        })
                          .then(function () {
                            // aggiorno anche il carrello

                            // controllo prima se esiste già un carrello
                            var cartId = "cart_" + cartUserName;

                            axios({
                              method: "get",
                              url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
                              headers: {
                                "X-WP-Nonce": nonce,
                              },
                            })
                              .then(function (response) {
                                console.log(
                                  response,
                                  response.status,
                                  response.data.length,
                                  response.data
                                );

                                if (200 === response.status) {
                                  var postData = {
                                    title: cartId,
                                    acf: {
                                      ecommerce_cart: {
                                        ecommerce_cart_user: parseInt(
                                          response.data[0].acf.ecommerce_cart
                                            .ecommerce_cart_user
                                        ),
                                        ecommerce_cart_rows: cartArray,
                                      },
                                    },
                                    status: "publish",
                                  };

                                  //console.log(postData);

                                  // aggiorno il carrello
                                  axios({
                                    method: "put",
                                    url:
                                      baseUrlWP +
                                      "wp/v2/cart/" +
                                      response.data[0].id,
                                    data: postData,
                                    headers: {
                                      "Content-Type": "application/json",
                                      "X-WP-Nonce": nonce,
                                    },
                                  })
                                    .then(function () {
                                      // nascondo preloader
                                      preloaderPopupCheckout.classList.add(
                                        "hidden"
                                      );

                                      // messaggio
                                      if (
                                        resultCheckoutBox &&
                                        resultCheckoutBox.classList.contains(
                                          "hidden"
                                        )
                                      ) {
                                        resultCheckoutBox.classList.remove(
                                          "hidden"
                                        );
                                      }

                                      if (
                                        resultCheckoutBoxOk &&
                                        resultCheckoutBoxOk.classList.contains(
                                          "hidden"
                                        )
                                      ) {
                                        resultCheckoutBoxOk.classList.remove(
                                          "hidden"
                                        );
                                      }

                                      if (resultCheckoutBoxKo) {
                                        resultCheckoutBoxKo.classList.add(
                                          "hidden"
                                        );
                                      }

                                      // vado alla pagina spedizione
                                      setTimeout(function () {
                                        var baseUrlGemels = document.querySelector(
                                          "#baseUrlGemels"
                                        ).value;
                                        document.location.href =
                                          baseUrlGemels + "/shipping/";
                                      }, 1000);
                                    })
                                    .catch(function (error) {
                                      console.error(error);
                                    });
                                } else {
                                }
                              })
                              .catch(function (error) {
                                console.error(error);
                              });
                          })
                          .catch(function (error) {
                            console.error(error);
                          });
                      }
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                close: function () {},
              },
            });
          });
        }

        // flatpickr localization IT
        var fp =
          typeof window !== "undefined" && window.flatpickr !== undefined
            ? window.flatpickr
            : {
                l10ns: {},
              };
        var Italian = {
          weekdays: {
            shorthand: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            longhand: [
              "Domenica",
              "Lunedì",
              "Martedì",
              "Mercoledì",
              "Giovedì",
              "Venerdì",
              "Sabato",
            ],
          },
          months: {
            shorthand: [
              "Gen",
              "Feb",
              "Mar",
              "Apr",
              "Mag",
              "Giu",
              "Lug",
              "Ago",
              "Set",
              "Ott",
              "Nov",
              "Dic",
            ],
            longhand: [
              "Gennaio",
              "Febbraio",
              "Marzo",
              "Aprile",
              "Maggio",
              "Giugno",
              "Luglio",
              "Agosto",
              "Settembre",
              "Ottobre",
              "Novembre",
              "Dicembre",
            ],
          },
          firstDayOfWeek: 1,
          ordinal: function () {
            return "°";
          },
          rangeSeparator: " al ",
          weekAbbreviation: "Se",
          scrollTitle: "Scrolla per aumentare",
          toggleTitle: "Clicca per cambiare",
          time_24hr: true,
        };
        fp.l10ns.it = Italian;
        var it = fp.l10ns;

        // inizializzo calendario flatpickr italiano e inglese
        var flatpickr_it = document.querySelectorAll(".flatpickr_it");
        var flatpickr_en = document.querySelectorAll(".flatpickr_en");
        if (flatpickr_it) {
          flatpickr_it.forEach(function (item) {
            var myCalendar = $(item);
            myCalendar.flatpickr({
              altInput: true,
              altFormat: "d/m/Y",
              dateFormat: "Y-m-d",
              minDate: new Date().fp_incr(7),
              locale: "it",
              onChange: function (selectedDates, dateStr, instance) {
                //console.info(selectedDates, dateStr, instance, instance.input);
                var currentInput = instance.input;
                var indexRow = currentInput.id.split("_")[2];
                console.info(indexRow);

                // aggiorno anche il DB
                debounce(function () {
                  return updateSingleDate(indexRow, dateStr);
                }, 500)();
              },
            });
          });
        }
        if (flatpickr_en) {
          flatpickr_en.forEach(function (item) {
            var myCalendar = $(item);
            myCalendar.flatpickr({
              altInput: true,
              altFormat: "d/m/Y",
              dateFormat: "Y-m-d",
              minDate: new Date().fp_incr(7),
              onChange: function (selectedDates, dateStr, instance) {
                //console.info(selectedDates, dateStr, instance, instance.input);
                var currentInput = instance.input;
                var indexRow = currentInput.id.split("_")[2];
                console.info(indexRow);

                // aggiorno anche il DB
                debounce(function () {
                  return updateSingleDate(indexRow, dateStr);
                }, 500)();
              },
            });
          });
        }
      },
      finalize: function () {},
    },

    // Shipping
    page_template_spedizione: {
      init: function () {
        /**
         * RIEPILOGO (da spedizione a riepilogo)
         */
        var riepilogoButton = document.querySelector("#riepilogo");
        if (riepilogoButton) {
          // preloader Riepilogo
          var preloaderPopupRiepilogo = document.querySelector(
            "#riepilogoPopup .preloader"
          );

          // result
          var resultRiepilogoBox = document.querySelector(
            "#riepilogoPopup .resultMessage"
          );
          var resultRiepilogoBoxKo = document.querySelector(
            "#riepilogoPopup .resultMessage .error"
          );
          var resultRiepilogoBoxOk = document.querySelector(
            "#riepilogoPopup .resultMessage .message"
          );

          riepilogoButton.addEventListener("click", function () {
            // input indirizzi
            var indirizzoSelected = "";
            var indirizzoSelectedCode = "";
            var indirizziSpedizione = document.querySelectorAll(
              ".indirizzoSpedizione"
            );
            indirizziSpedizione.forEach(function (item) {
              if (item.checked) {
                indirizzoSelected = item
                  .closest("div")
                  .querySelector("label")
                  .innerHTML.trimStart()
                  .trimEnd();
                indirizzoSelectedCode = item.value;
              }
            });

            // mostro errore se non ho selezionato un indirizzo
            if (indirizzoSelected === "") {
              indirizziSpedizione.forEach(function (item) {
                item.closest("div").classList.add("error");
              });
            } else {
              // aggiorno ordine con indirizzo spedizione

              // mostro preloader
              if (
                preloaderPopupRiepilogo &&
                preloaderPopupRiepilogo.classList.contains("hidden")
              ) {
                preloaderPopupRiepilogo.classList.remove("hidden");
              }

              // mostro popup
              $.magnificPopup.open({
                items: {
                  src: "#riepilogoPopup",
                },
                type: "inline",
                midClick: true,
                mainClass: "popupGenerico",
                closeBtnInside: false,
                closeOnBgClick: false,
                callbacks: {
                  open: function (e) {
                    // baseUrlWP
                    var baseUrlWP = document.querySelector("#baseUrlWP").value;

                    // nonce
                    var nonce = document.querySelector("#nonce").value;

                    // recupero dati utente collegato
                    var cartUserId = document.querySelector("#currentUserId")
                      .value;
                    var codiceCliente = document.querySelector(
                      "#currentClientCode"
                    ).value;

                    // recupero token ordine corrente
                    var currentOrderId = document.querySelector(
                      "#GEMELS_ORDER_COOKIE"
                    ).value;

                    // cerco l'ordine dell'utente
                    axios({
                      method: "get",
                      url:
                        baseUrlWP +
                        "wp/v2/user-order/?search=" +
                        currentOrderId,
                      headers: {
                        "X-WP-Nonce": nonce,
                      },
                    })
                      .then(function (response) {
                        //console.log(response, response.status, response.data.length, response.data);

                        if (200 === response.status) {
                          // ho trovato l'ordine, lo aggiorno con PUT method
                          if (response.data.length > 0) {
                            // preparo body
                            var postData = {
                              title: currentOrderId,
                              acf: {
                                ecommerce_user_orders: {
                                  ecommerce_user_orders_orderId: currentOrderId,
                                  ecommerce_user_orders_user: parseInt(
                                    cartUserId
                                  ),
                                  ecommerce_user_orders_codiceCliente: codiceCliente,
                                  ecommerce_user_orders_shippingAddress: indirizzoSelected,
                                  ecommerce_user_orders_shippingAddressCode: indirizzoSelectedCode,
                                },
                              },
                              status: "publish",
                            };

                            // aggiorno il record
                            axios({
                              method: "put",
                              url:
                                baseUrlWP +
                                "wp/v2/user-order/" +
                                response.data[0].id,
                              data: postData,
                              headers: {
                                "Content-Type": "application/json",
                                "X-WP-Nonce": nonce,
                              },
                            })
                              .then(function (response) {
                                //console.log(response, response.status, response.data.length, response.data);

                                if (200 === response.status) {
                                  // nascondo preloader
                                  preloaderPopupRiepilogo.classList.add(
                                    "hidden"
                                  );

                                  // messaggio
                                  if (
                                    resultRiepilogoBox &&
                                    resultRiepilogoBox.classList.contains(
                                      "hidden"
                                    )
                                  ) {
                                    resultRiepilogoBox.classList.remove(
                                      "hidden"
                                    );
                                  }

                                  if (
                                    resultRiepilogoBoxOk &&
                                    resultRiepilogoBoxOk.classList.contains(
                                      "hidden"
                                    )
                                  ) {
                                    resultRiepilogoBoxOk.classList.remove(
                                      "hidden"
                                    );
                                  }

                                  if (resultRiepilogoBoxKo) {
                                    resultRiepilogoBoxKo.classList.add(
                                      "hidden"
                                    );
                                  }

                                  setTimeout(function () {
                                    // vado a shipping
                                    var baseUrlGemels = document.querySelector(
                                      "#baseUrlGemels"
                                    ).value;
                                    document.location.href =
                                      baseUrlGemels + "/order-summary/";
                                  }, 1000);
                                } else {
                                  resultRiepilogoBox.classList.remove("hidden");
                                  resultRiepilogoBoxKo.classList.remove(
                                    "hidden"
                                  );
                                  if (
                                    resultRiepilogoBoxOk &&
                                    !resultRiepilogoBoxOk.classList.contains(
                                      "hidden"
                                    )
                                  ) {
                                    resultRiepilogoBoxOk.classList.add(
                                      "hidden"
                                    );
                                  }
                                }
                              })
                              .catch(function (e) {
                                console.error(error);

                                resultRiepilogoBox.classList.remove("hidden");
                                resultRiepilogoBoxKo.classList.remove("hidden");
                                if (
                                  resultRiepilogoBoxOk &&
                                  !resultRiepilogoBoxOk.classList.contains(
                                    "hidden"
                                  )
                                ) {
                                  resultRiepilogoBoxOk.classList.add("hidden");
                                }
                              });
                          }
                        }
                      })
                      .catch(function (error) {
                        console.error(error);

                        resultRiepilogoBox.classList.remove("hidden");
                        resultRiepilogoBoxKo.classList.remove("hidden");
                        if (
                          resultRiepilogoBoxOk &&
                          !resultRiepilogoBoxOk.classList.contains("hidden")
                        ) {
                          resultRiepilogoBoxOk.classList.add("hidden");
                        }
                      });
                  },
                  close: function () {},
                },
              });
            }
          });
        }

        var indirizziSpedizioneElements = document.querySelectorAll(
          ".indirizzoSpedizione"
        );
        if (indirizziSpedizioneElements) {
          indirizziSpedizioneElements.forEach(function (item) {
            item.addEventListener("click", function (item2) {
              indirizziSpedizioneElements.forEach(function (item3) {
                if (item3.closest("div").classList.contains("selected")) {
                  item3.closest("div").classList.remove("selected");
                }
                if (item3.closest("div").classList.contains("error")) {
                  item3.closest("div").classList.remove("error");
                }
              });

              item2.target.closest("div").classList.add("selected");
            });
          });
        }
      },
      finalize: function () {},
    },

    // Riepilogo
    page_template_riepilogo: {
      init: function () {
        /**
         * CONFERMA ORDINE (da riepilogo a conferma)
         */
        var confermaOrdineButton = document.querySelector("#confermaOrdine");
        if (confermaOrdineButton) {
          // preloader Conferma Ordine
          var preloaderPopupConfermaOrdine = document.querySelector(
            "#confermaOrdinePopup .preloader"
          );

          // result
          var resultConfermaBox = document.querySelector(
            "#confermaOrdinePopup .resultMessage"
          );
          var resultConfermaBoxKo = document.querySelector(
            "#confermaOrdinePopup .resultMessage .error"
          );
          var resultConfermaBoxOk = document.querySelector(
            "#confermaOrdinePopup .resultMessage .message"
          );

          confermaOrdineButton.addEventListener("click", function () {
            // mostro preloader
            if (
              preloaderPopupConfermaOrdine &&
              preloaderPopupConfermaOrdine.classList.contains("hidden")
            ) {
              preloaderPopupConfermaOrdine.classList.remove("hidden");
            }

            // mostro popup
            $.magnificPopup.open({
              items: {
                src: "#confermaOrdinePopup",
              },
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  // baseUrlWP
                  var baseUrlWP = document.querySelector("#baseUrlWP").value;

                  // nonce
                  var nonce = document.querySelector("#nonce").value;

                  // recupero token ordine corrente
                  var currentOrderId = document.querySelector(
                    "#GEMELS_ORDER_COOKIE"
                  ).value;

                  // invio ordine a EOS
                  axios({
                    method: "get",
                    url: baseUrlWP + "gemels/v1/send-order/" + currentOrderId,
                    headers: {
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      //console.log(response, response.status, response.data.length, response.data);
                      if (200 === response.status) {
                        // finalmente posso andare alla pagina di conferma ordine

                        // nascondo preloader
                        preloaderPopupConfermaOrdine.classList.add("hidden");

                        // messaggio
                        if (
                          resultConfermaBox &&
                          resultConfermaBox.classList.contains("hidden")
                        ) {
                          resultConfermaBox.classList.remove("hidden");
                        }

                        if (
                          resultConfermaBoxOk &&
                          resultConfermaBoxOk.classList.contains("hidden")
                        ) {
                          resultConfermaBoxOk.classList.remove("hidden");
                        }

                        if (resultConfermaBoxKo) {
                          resultConfermaBoxKo.classList.add("hidden");
                        }

                        setTimeout(function () {
                          // vado a conferma ordine
                          var baseUrlGemels = document.querySelector(
                            "#baseUrlGemels"
                          ).value;
                          //console.log(baseUrlGemels + "order-sent/?order_id=" + currentOrderId);
                          document.location.href =
                            baseUrlGemels +
                            "/order-sent/?order_id=" +
                            currentOrderId;
                        }, 1000);
                      }
                    })
                    .catch(function (error) {
                      console.error(error);

                      resultConfermaBox.classList.remove("hidden");
                      resultConfermaBoxKo.classList.remove("hidden");
                      if (
                        resultConfermaBoxOk &&
                        !resultConfermaBoxOk.classList.contains("hidden")
                      ) {
                        resultConfermaBoxOk.classList.add("hidden");
                      }
                    });
                },
                close: function () {},
              },
            });
          });
        }
      },
      finalize: function () {},
    },

    // Elenco ordini
    page_template_ordini: {
      init: function () {
        // popup clona ordine

        // elementi in comune
        var preloaderCloneOrderPopup = document.querySelector(
          "#cloneOrderPopup .preloader"
        );
        var resultCloneOrderMessage = document.querySelector(
          "#cloneOrderPopup .resultMessage"
        );
        var resultCloneOrderOk = resultCloneOrderMessage.querySelector(
          ".message"
        );
        var resultCloneOrderKo = resultCloneOrderMessage.querySelector(
          ".error"
        );

        var cloneOrdersElements = document.querySelectorAll(".orderClone");
        cloneOrdersElements.forEach(function (item) {
          item.addEventListener("click", function (e) {
            var orderId = e.target.closest("button").id.split("clone_")[1];

            // mostro popup
            $.magnificPopup.open({
              items: {
                src: "#cloneOrderPopup",
              },
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  // nonce
                  var nonce = document.querySelector("#nonce").value;

                  // mostro il preloader
                  if (
                    preloaderCloneOrderPopup &&
                    preloaderCloneOrderPopup.classList.contains("hidden")
                  ) {
                    preloaderCloneOrderPopup.classList.remove("hidden");
                  }

                  // baseUrl WP
                  var baseUrlWP = document.querySelector("#baseUrlWP").value;

                  axios({
                    method: "get",
                    url: baseUrlWP + "wp/v2/user-order/?search=" + orderId,
                    headers: {
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      //console.log(response, response.status, response.data.length, response.data);

                      // nascondo preloader
                      preloaderCloneOrderPopup.classList.add("hidden");

                      if (200 === response.status) {
                        // aggiungo titolo
                        var cloneOrderTitle = document.querySelector(
                          "#ecommerceOrderName"
                        );
                        cloneOrderTitle.innerHTML = orderId;

                        // mostro pulsante per duplicare ordine
                        var content = document.querySelector(
                          "#cloneOrderPopup .content"
                        );
                        content.classList.remove("hidden");

                        // aggiorno userOrderId
                        var cartOrderUserId = document.querySelector(
                          "#cloneOrderPopup #cartOrderUserId"
                        );
                        var currentUserId = document.querySelector(
                          "#currentUserId"
                        ).value;
                        cartOrderUserId.value = currentUserId;

                        // storo il carrello dell'ordine da clonare
                        var cartOrderRows = document.querySelector(
                          "#cloneOrderPopup #cartOrderRows"
                        );
                        cartOrderRows.value = JSON.stringify(
                          response.data[0].acf.ecommerce_user_orders
                            .ecommerce_user_orders_cart
                        );
                      } else {
                        resultCloneOrderMessage.classList.remove("hidden");
                        resultCloneOrderKo.classList.remove("hidden");
                        if (
                          resultCloneOrderOk &&
                          !resultCloneOrderOk.classList.contains("hidden")
                        ) {
                          resultCloneOrderOk.classList.add("hidden");
                        }
                      }
                    })
                    .catch(function (error) {
                      console.error(error);

                      resultCloneOrderMessage.classList.remove("hidden");
                      resultCloneOrderKo.classList.remove("hidden");
                      if (
                        resultCloneOrderOk &&
                        !resultCloneOrderOk.classList.contains("hidden")
                      ) {
                        resultCloneOrderOk.classList.add("hidden");
                      }
                    });
                },
                close: function () {
                  // nascondo contenuto popup
                  var content = document.querySelector(
                    "#cloneOrderPopup .content"
                  );
                  content.classList.add("hidden");

                  resultCloneOrderMessage.classList.add("hidden");
                  resultCloneOrderKo.classList.add("hidden");
                  resultCloneOrderOk.classList.add("hidden");
                },
              },
            });
          });
        });

        // aggiungo evento su pulsante "duplica ordine"
        var ecommerceCloneOrder = document.querySelector(
          "#cloneOrderPopup .bottom button"
        );
        if (ecommerceCloneOrder) {
          ecommerceCloneOrder.addEventListener("click", function (e) {
            e.stopPropagation();

            // nascondo contenuto popup e mostro preloader
            var content = document.querySelector("#cloneOrderPopup .content");
            content.classList.add("hidden");
            preloaderCloneOrderPopup.classList.remove("hidden");

            // recupero i dati per aggiornare il carrello con i dati dell'ordine clonato

            // recupero il carrello
            var cartUserName = document.querySelector("#currentUserName").value;
            var cartId = "cart_" + cartUserName;

            // nonce
            var nonce = document.querySelector("#nonce").value;

            // baseUrl WP
            var baseUrlWP = document.querySelector("#baseUrlWP").value;

            // userId
            var cartOrderUserId = document.querySelector("#cartOrderUserId")
              .value;

            // carrello del vecchio ordine da clonare
            var cartOrderRows = document.querySelector("#cartOrderRows").value;
            if (cartOrderRows) {
              cartOrderRows = JSON.parse(cartOrderRows);
            }

            // rimuovo dal carrello i dati non necessari del vecchio ordine e modifico le chiavi (sono quelle della struttura user-order, le converto alla struttura cart)
            cartOrderRows.forEach(function (item, index) {
              cartOrderRows[index].ecommerce_cart_rows_model =
                item.ecommerce_user_orders_cart_model;
              cartOrderRows[index].ecommerce_cart_rows_text =
                item.ecommerce_user_orders_cart_text;
              cartOrderRows[index].ecommerce_cart_rows_unitPrice =
                item.ecommerce_user_orders_cart_unitPrice;
              cartOrderRows[index].ecommerce_cart_rows_qnt =
                item.ecommerce_user_orders_cart_qnt;
              cartOrderRows[index].ecommerce_cart_rows_shippingDate = "";
              cartOrderRows[index].ecommerce_cart_rows_qtyPerParcel =
                item.ecommerce_user_orders_cart_qtyPerParcel;
            });

            // controllo prima se esiste già un carrello
            axios({
              method: "get",
              url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
              headers: {
                "X-WP-Nonce": nonce,
              },
            })
              .then(function (response) {
                //console.log(response, response.status, response.data.length, response.data);

                if (200 === response.status) {
                  var methodToCall = "";
                  var urlToCall = "";

                  // ho trovato già un carrello per l'utente, lo aggiorno con PUT method
                  if (response.data.length > 0) {
                    methodToCall = "put";
                    urlToCall = baseUrlWP + "wp/v2/cart/" + response.data[0].id;
                  } else {
                    // non ho trovato un carrello, lo creo con POST method
                    methodToCall = "post";
                    urlToCall = baseUrlWP + "wp/v2/cart";
                  }

                  var postData = {
                    title: cartId,
                    acf: {
                      ecommerce_cart: {
                        ecommerce_cart_user: parseInt(cartOrderUserId),
                        ecommerce_cart_rows: cartOrderRows,
                      },
                    },
                    status: "publish",
                  };

                  //console.log(cartOrderRows);

                  // aggiungo il record
                  axios({
                    method: methodToCall,
                    url: urlToCall,
                    data: postData,
                    headers: {
                      "Content-Type": "application/json",
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      // nascondo preloader
                      preloaderCloneOrderPopup.classList.add("hidden");

                      // messaggio
                      if (
                        resultCloneOrderMessage &&
                        resultCloneOrderMessage.classList.contains("hidden")
                      ) {
                        resultCloneOrderMessage.classList.remove("hidden");
                      }

                      if (
                        resultCloneOrderOk &&
                        resultCloneOrderOk.classList.contains("hidden")
                      ) {
                        resultCloneOrderOk.classList.remove("hidden");
                      }

                      if (resultCloneOrderKo) {
                        resultCloneOrderKo.classList.add("hidden");
                      }

                      // ricarico la pagina dopo tot secondi
                      setTimeout(function () {
                        var baseUrlGemels = document.querySelector(
                          "#baseUrlGemels"
                        ).value;
                        document.location.href = baseUrlGemels + "/cart/";
                      }, 1000);
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                }
              })
              .catch(function (error) {
                console.error(error);
              });
          });
        }

        // cloneOrderPopup -> chiudi popup
        var cloneOrderClosePopup = document.querySelector(
          "#cloneOrderPopup .closePopup"
        );
        if (cloneOrderClosePopup) {
          cloneOrderClosePopup.addEventListener("click", function () {
            $.magnificPopup.close();
          });
        }
      },
      finalize: function () {},
    },

    // Pagina azienda
    page_template_chisiamo: {
      init: function () {
        // controllo risoluzione mobile
        var windowWidth = document.documentElement.clientWidth;
        if (windowWidth < 768) {
          return;
        }

        // effetto su hover menu
        var menu_elements = document.querySelectorAll(".box-menu");
        menu_elements.forEach(function (element) {
          element.addEventListener("mouseover", function (e) {
            var parent = e.target.closest("div");
            var menuId = parent.getAttribute("data-box");
            var target_elements_noselected = document.querySelectorAll(
              ".single-pointer .title"
            );
            target_elements_noselected.forEach(function (target_element) {
              if (target_element.classList.contains("selected")) {
                target_element.classList.remove("selected");
              }
            });
            var target_elements = document.querySelectorAll(".number" + menuId);
            target_elements.forEach(function (target_element) {
              target_element.classList.add("selected");
            });
          });

          element.addEventListener("mouseleave", function (e) {
            var target_elements_noselected = document.querySelectorAll(
              ".single-pointer .title"
            );
            target_elements_noselected.forEach(function (target_element) {
              if (target_element.classList.contains("selected")) {
                target_element.classList.remove("selected");
              }
            });
          });

          element.addEventListener("click", function (e) {
            // nascondo prima gli altri
            var menu_elements2 = document.querySelectorAll(".box-menu");
            menu_elements2.forEach(function (element) {
              var descElement = element.querySelector(".desc");
              if (descElement.classList.contains("selected")) {
                descElement.classList.remove("selected");
              }
            });

            var descElementSelected = e.target
              .closest("div")
              .querySelector(".desc");
            descElementSelected.classList.add("selected");
          });
        });
      },
      finalize: function () {},
    },

    // Pagina team
    page_template_team: {
      init: function () {
        var select_persons = document.querySelectorAll(".select_person");
        if (select_persons) {
          select_persons.forEach(function (person) {
            person.addEventListener("mouseenter", function (e) {
              var select_persons2 = document.querySelectorAll(".select_person");
              select_persons2.forEach(function (element) {
                if (element.classList.contains("selected")) {
                  element.classList.remove("selected");
                }
              });

              e.target.classList.add("selected");

              var person_id = e.target.id;
              var target_id = "target_" + person_id;

              var target_persons = document.querySelectorAll(".target_person");
              target_persons.forEach(function (element) {
                if (element.classList.contains("visible")) {
                  element.classList.remove("visible");
                }
              });

              var target_image_to_show = document.querySelector(
                "#" + target_id
              );
              if (target_image_to_show) {
                target_image_to_show.classList.add("visible");
              }
            });
          });
        }

        var select_persons_dropdown = document.querySelector(
          ".elenco_team_select"
        );
        if (select_persons_dropdown) {
          select_persons_dropdown.addEventListener("change", function (e) {
            var person_selected_id =
              e.target.options[e.target.selectedIndex].dataset.id;
            var target_id = "target_" + person_selected_id;

            var target_persons = document.querySelectorAll(".target_person");
            target_persons.forEach(function (element) {
              if (element.classList.contains("visible")) {
                element.classList.remove("visible");
              }
            });

            var target_image_to_show = document.querySelector("#" + target_id);
            if (target_image_to_show) {
              target_image_to_show.classList.add("visible");
            }
          });
        }
      },
      finalize: function () {},
    },

    // Pagina contatti
    page_template_contatti: {
      init: function () {},
      finalize: function () {
        var contatti_ok = document.querySelector(".contatti_ok");
        if (contatti_ok !== undefined) {
          setTimeout(function () {
            contatti_ok.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "end",
            });
          }, 100);
        }
      },
    },

    // Pagina wishlist
    page_template_wishlist: {
      init: function () {
        // popup addToCart
        // variabili condivise popup
        var baseUrlWP = document.querySelector("#baseUrlWP").value;
        var currentUserName = document.querySelector("#currentUserName").value;
        var currentUserId = document.querySelector("#currentUserId").value;
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var currentDate = year + "-" + month + "-" + day;

        // nonce
        var nonce = document.querySelector("#nonce").value;

        // campi TOP nel popup
        var ecommerceProductName = document.querySelector(
          "#ecommerceProductName"
        );

        // table dati
        var tableData = document.querySelector(
          "#addToCartPopup .table_content"
        );
        if (tableData) {
          tableData.classList.add("hidden");

          // nascondo riga "materiale" e "dn" (non mi arriva da web service)
          var materiale = tableData.querySelector(".materiale");
          if (materiale) {
            materiale.remove();
          }

          var dn = tableData.querySelector(".dn");
          if (dn) {
            dn.remove();
          }
        }

        // dati tabella
        var ecommerceModelName = document.querySelector(
          "#addToCartPopup .modelName"
        );
        var ecommerceModelDesc = document.querySelector(
          "#addToCartPopup .modelDesc"
        );
        var ecommerceModelPrice = document.querySelector(
          "#addToCartPopup .modelPrice"
        );

        // preloader
        var preloaderPopup = document.querySelector(
          "#addToCartPopup .preloader"
        );
        if (preloaderPopup && preloaderPopup.classList.contains("hidden")) {
          preloaderPopup.classList.remove("hidden");
        }

        // result
        var resultBox = document.querySelector(
          "#addToCartPopup .resultMessage"
        );
        var resultBoxKo = document.querySelector(
          "#addToCartPopup .resultMessage .error"
        );
        var resultBoxOk = document.querySelector(
          "#addToCartPopup .resultMessage .message"
        );

        // input nascosti popup
        var unitPricePopup = document.querySelector("#unitPricePopup");
        var modelNamePopup = document.querySelector("#modelNamePopup");
        var modelDescPopup = document.querySelector("#modelDescPopup");
        var modelQtyPerParcelPopup = document.querySelector(
          "#modelQtyPerParcelPopup"
        );

        // icona Aggiungi a carrello
        var addToCartList = document.querySelectorAll(".addToCart");
        if (addToCartList) {
          addToCartList.forEach(function (item) {
            var thisElement = $(item);

            thisElement.magnificPopup({
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  // chiamata a servizio EOS per recupero prezzo
                  var parentTd = item.closest(".wishlistElement");
                  var ecommerce_product_name = parentTd.querySelector(
                    ".ecommerce_product_name"
                  ).value;
                  var ecommerce_model_name = parentTd.querySelector(
                    ".ecommerce_model_name"
                  ).value;
                  var ecommerce_model_description = parentTd.querySelector(
                    ".ecommerce_model_description"
                  ).value;
                  var ecommerce_model_qtyPerParcel = parentTd.querySelector(
                    ".ecommerce_model_qtyPerParcel"
                  ).value;

                  // aggiorno input hidden per l'evento aggiungi a carrello
                  modelNamePopup.value = ecommerce_model_name;
                  modelDescPopup.value = ecommerce_model_description;
                  modelQtyPerParcelPopup.value = ecommerce_model_qtyPerParcel;

                  // aggiorno campi TOP nel popup
                  ecommerceProductName.innerHTML = ecommerce_product_name;

                  // recupero prezzo corrente da EOS
                  axios({
                    method: "get",
                    url:
                      baseUrlWP +
                      "gemels/v1/search-model/" +
                      ecommerce_model_name,
                    headers: {
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      //console.log(response, response.status, response.data.length);

                      if (
                        200 === response.status &&
                        response.data.value !== undefined
                      ) {
                        var dataModel = response.data.value;
                        var price = dataModel[0].price; // -> N.B. COMMENTATO TEMPORANEAMENTE PERCHE' I PREZZI SONO A 0 04/10/2023
                        //price = 0;

                        var listinoUtenteId = "listino_" + currentUserName;

                        // aggiorno campo nascosto
                        unitPricePopup.value = price;
                        -(
                          // recupero listino dell'utente "user_price"
                          axios({
                            method: "get",
                            url:
                              baseUrlWP +
                              "wp/v2/user-price/?search=" +
                              listinoUtenteId,
                            headers: {
                              "X-WP-Nonce": nonce,
                            },
                          })
                            .then(function (response) {
                              //console.log(response, response.status, response.data.length);

                              if (200 === response.status) {
                                var methodToCall = "";
                                var urlToCall = "";
                                var currentListinoRows = [];
                                var oldListinoRows = [];

                                var newItem = {
                                  user_prices_rows_model: ecommerce_model_name,
                                  user_prices_rows_price: price,
                                  user_prices_rows_modifiedDate: new Date(),
                                };

                                // ho trovato già un listino per l'utente, lo aggiorno con PUT method
                                if (response.data.length > 0) {
                                  methodToCall = "put";
                                  urlToCall =
                                    baseUrlWP +
                                    "wp/v2/user-price/" +
                                    response.data[0].id;

                                  // recupero l'elenco di prezzi già inseriti nel listino, cerco il codice modello e lo aggiorno o aggiungo
                                  var modelFound = false;
                                  oldListinoRows =
                                    response.data[0].acf.user_prices
                                      .user_prices_rows;
                                  oldListinoRows.forEach(function (
                                    item,
                                    index
                                  ) {
                                    //console.log(item, index, ecommerce_model_name, item.user_prices_rows_model);
                                    if (
                                      item.user_prices_rows_model ===
                                      ecommerce_model_name
                                    ) {
                                      // aggiorno prezzo
                                      oldListinoRows[
                                        index
                                      ].user_prices_rows_price = price;
                                      oldListinoRows[
                                        index
                                      ].user_prices_rows_modifiedDate = new Date();
                                      modelFound = true;
                                    }
                                  });

                                  if (modelFound) {
                                    // ho trovato il modello, quindi aggiorno solo il prezzo e la data
                                    currentListinoRows = oldListinoRows;
                                  } else {
                                    // non ho trovato il modello, quindi è un prezzo nuovo, lo aggiungo
                                    oldListinoRows.push(newItem);
                                    currentListinoRows = oldListinoRows;
                                  }
                                } else {
                                  // non ho trovato un listino, lo creo con POST method
                                  methodToCall = "post";
                                  urlToCall = baseUrlWP + "wp/v2/user-price";

                                  currentListinoRows.push(newItem);
                                }

                                var postData = {
                                  title: listinoUtenteId,
                                  acf: {
                                    user_prices: {
                                      user_prices_user: parseInt(currentUserId),
                                      user_prices_rows: currentListinoRows,
                                    },
                                  },
                                  status: "publish",
                                };

                                // aggiungo il record
                                axios({
                                  method: methodToCall,
                                  url: urlToCall,
                                  data: postData,
                                  headers: {
                                    "Content-Type": "application/json",
                                    "X-WP-Nonce": nonce,
                                  },
                                })
                                  .then(function (response) {})
                                  .catch(function (error) {
                                    console.error(error);
                                  });
                              }
                            })
                            .catch(function (error) {
                              console.error(error);
                            })
                        );

                        // aggiorno dati TABLE nel popup

                        ecommerceModelName.innerHTML = ecommerce_model_name;
                        ecommerceModelDesc.innerHTML = ecommerce_model_description;
                        ecommerceModelPrice.innerHTML =
                          "€ " + price.toString().replace(".", ","); // -> N.B. COMMENTATO TEMPORANEAMENTE PERCHE' I PREZZI SONO A 0 04/10/2023
                        //ecommerceModelPrice.innerHTML = "€ 0";

                        // quantità
                        var currentQty = document.querySelector("#qty");

                        // input aggiungi o togli quantità
                        var stepQnt =
                          ecommerce_model_qtyPerParcel === "0"
                            ? 1
                            : ecommerce_model_qtyPerParcel;
                        currentQty.value = stepQnt;
                        //console.log(stepQnt);

                        // eventi su + e -
                        var addQnt = document.querySelector(
                          "#addToCartPopup .modelQnt .piu"
                        );
                        var removeQnt = document.querySelector(
                          "#addToCartPopup .modelQnt .meno"
                        );
                        var oldQnt = parseInt(stepQnt);
                        var newQnt = parseInt(stepQnt);
                        if (addQnt && removeQnt) {
                          addQnt.addEventListener("click", function () {
                            newQnt += oldQnt;
                            currentQty.value = newQnt;
                          });

                          removeQnt.addEventListener("click", function () {
                            if (newQnt - oldQnt >= 1) {
                              newQnt -= oldQnt;
                              currentQty.value = newQnt;
                            }
                          });
                        }

                        // nascondo preloader
                        preloaderPopup.classList.add("hidden");

                        // mostro dati
                        if (tableData) {
                          tableData.classList.remove("hidden");
                        }
                      } else {
                        // messaggio
                        if (
                          resultBox &&
                          resultBox.classList.contains("hidden")
                        ) {
                          resultBox.classList.remove("hidden");
                        }

                        if (
                          resultBoxKo &&
                          resultBoxKo.classList.contains("hidden")
                        ) {
                          resultBoxKo.classList.remove("hidden");
                        }

                        if (resultBoxOk) {
                          resultBoxOk.classList.add("hidden");
                        }

                        // nascondo preloader
                        preloaderPopup.classList.add("hidden");
                      }
                    })
                    .catch(function (error) {
                      console.error(error);

                      // messaggio
                      if (resultBox && resultBox.classList.contains("hidden")) {
                        resultBox.classList.remove("hidden");
                      }

                      if (
                        resultBoxKo &&
                        resultBoxKo.classList.contains("hidden")
                      ) {
                        resultBoxKo.classList.remove("hidden");
                      }

                      if (resultBoxOk) {
                        resultBoxOk.classList.add("hidden");
                      }

                      // nascondo preloader
                      preloaderPopup.classList.add("hidden");
                    });
                },
                close: function () {
                  // resetto campi vari

                  // quantità
                  var currentQty = document.querySelector("#qty");
                  currentQty.value = 1;

                  modelDescPopup.value = "";
                  modelQtyPerParcelPopup.value = "";
                  tableData.classList.add("hidden");
                  preloaderPopup.classList.remove("hidden");

                  // messaggio
                  resultBox.classList.add("hidden");

                  if (
                    resultBoxOk &&
                    !resultBoxOk.classList.contains("hidden")
                  ) {
                    resultBoxOk.classList.add("hidden");
                  }

                  if (
                    resultBoxKo &&
                    !resultBoxKo.classList.contains("hidden")
                  ) {
                    resultBoxKo.classList.add("hidden");
                  }
                },
              },
            });
          });
        }

        // funzione aggiungi a carrello
        // aggiungo evento su pulsante "aggiungi a carrello"
        var ecommerceModelAddToCart = document.querySelector(
          "#addToCartPopup .bottom button"
        );
        if (ecommerceModelAddToCart) {
          ecommerceModelAddToCart.addEventListener("click", function (e) {
            e.stopPropagation();

            // nascondo tabella dati e mostro preloader
            tableData.classList.add("hidden");
            preloaderPopup.classList.remove("hidden");

            // aggiungo il record al carrello
            var cartUserId = document.querySelector("#currentUserId").value;
            var cartUserName = document.querySelector("#currentUserName").value;
            var cartUnitPrice = unitPricePopup.value;
            var cartModelName = modelNamePopup.value;
            var cartModelDesc = modelDescPopup.value;
            var cartQtyPerParcel = modelQtyPerParcelPopup.value;

            // quantità
            var currentQty = document.querySelector("#qty");
            var cartQty = currentQty.value;

            var cartId = "cart_" + cartUserName;

            // controllo prima se esiste già un carrello
            axios({
              method: "get",
              url: baseUrlWP + "wp/v2/cart/?search=" + cartId,
              headers: {
                "X-WP-Nonce": nonce,
              },
            })
              .then(function (response) {
                console.log(
                  response,
                  response.status,
                  response.data.length,
                  response.data
                );

                if (200 === response.status) {
                  var methodToCall = "";
                  var urlToCall = "";
                  var currentCartRows = [];
                  var oldCartRows = [];

                  var newItem = {
                    ecommerce_cart_rows_model: cartModelName,
                    ecommerce_cart_rows_text: cartModelDesc,
                    ecommerce_cart_rows_unitPrice: cartUnitPrice,
                    ecommerce_cart_rows_qnt: parseInt(cartQty),
                    ecommerce_cart_rows_qtyPerParcel: parseInt(
                      cartQtyPerParcel
                    ),
                  };

                  // ho trovato già un carrello per l'utente, lo aggiorno con PUT method
                  if (response.data.length > 0) {
                    console.log("ho già un carrello");
                    methodToCall = "put";
                    urlToCall = baseUrlWP + "wp/v2/cart/" + response.data[0].id;

                    // recupero l'elenco di prodotti già inseriti a carrello ed aggiungo il nuovo in testata
                    oldCartRows =
                      response.data[0].acf.ecommerce_cart.ecommerce_cart_rows;

                    // se il carrello era vuoto, faccio push, altrimenti unshift
                    if (oldCartRows && oldCartRows.length > 0) {
                      currentCartRows = oldCartRows;
                      currentCartRows.unshift(newItem);
                    } else {
                      currentCartRows.push(newItem);
                    }
                  } else {
                    console.log("non ho un carrello");
                    // non ho trovato un carrello, lo creo con POST method
                    methodToCall = "post";
                    urlToCall = baseUrlWP + "wp/v2/cart";

                    currentCartRows.push(newItem);
                  }

                  var postData = {
                    title: cartId,
                    acf: {
                      ecommerce_cart: {
                        ecommerce_cart_user: parseInt(cartUserId),
                        ecommerce_cart_rows: currentCartRows,
                      },
                    },
                    status: "publish",
                  };

                  //console.log(currentCartRows);

                  // aggiungo il record
                  axios({
                    method: methodToCall,
                    url: urlToCall,
                    data: postData,
                    headers: {
                      "Content-Type": "application/json",
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      // nascondo preloader
                      preloaderPopup.classList.add("hidden");

                      // messaggio
                      if (resultBox && resultBox.classList.contains("hidden")) {
                        resultBox.classList.remove("hidden");
                      }

                      if (
                        resultBoxOk &&
                        resultBoxOk.classList.contains("hidden")
                      ) {
                        resultBoxOk.classList.remove("hidden");
                      }

                      if (resultBoxKo) {
                        resultBoxKo.classList.add("hidden");
                      }
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                }
              })
              .catch(function (error) {
                console.error(error);
              });
          });
        }

        // addToCart -> chiudi popup
        var addToCartClosePopup = document.querySelector(
          "#addToCartPopup .closePopup"
        );
        if (addToCartClosePopup) {
          addToCartClosePopup.addEventListener("click", function () {
            $.magnificPopup.close();
          });
        }

        // popup rimuovi articolo da wishlist
        var removeFromWishlistList = document.querySelectorAll(
          ".removeFromWishlist"
        );
        if (removeFromWishlistList) {
          // elementi in comune
          var preloaderRemoveWishlistPopup = document.querySelector(
            "#removeFromWishlistPopup .preloader"
          );
          var resultMessage = document.querySelector(
            "#removeFromWishlistPopup .resultMessage"
          );
          var resultOk = resultMessage.querySelector(".message");
          var resultKo = resultMessage.querySelector(".error");

          removeFromWishlistList.forEach(function (item) {
            var thisElement = $(item);

            thisElement.magnificPopup({
              type: "inline",
              midClick: true,
              mainClass: "popupGenerico",
              closeBtnInside: false,
              closeOnBgClick: false,
              callbacks: {
                open: function (e) {
                  parentTr = item.closest(".wishlistElement");
                  var rowIdElement = parentTr.id;
                  var rowId = rowIdElement.split("_")[2];
                  var modelName = parentTr
                    .querySelector(".modelName")
                    .innerHTML.trimStart()
                    .trimEnd();
                  var modelId = parentTr.querySelector(".ecommerce_model_id")
                    .value;
                  var ecommerceModelName = document.querySelector(
                    "#ecommerceModelName"
                  );
                  ecommerceModelName.innerHTML = modelName;

                  // aggiorno campo nascosto modelRowId
                  var modelRowId = document.querySelector(
                    "#removeFromWishlistPopup #modelRowId"
                  );
                  modelRowId.value = modelId;

                  // recupero la wishlist
                  var wishlistUserName = document.querySelector(
                    "#currentUserName"
                  ).value;
                  var wishlistId = "wishlist_" + wishlistUserName;

                  // nonce
                  var nonce = document.querySelector("#nonce").value;

                  // mostro il preloader
                  if (
                    preloaderRemoveWishlistPopup &&
                    preloaderRemoveWishlistPopup.classList.contains("hidden")
                  ) {
                    preloaderRemoveWishlistPopup.classList.remove("hidden");
                  }

                  // baseUrl WP
                  var baseUrlWP = document.querySelector("#baseUrlWP").value;

                  axios({
                    method: "get",
                    url: baseUrlWP + "wp/v2/wishlist/?search=" + wishlistId,
                    headers: {
                      "X-WP-Nonce": nonce,
                    },
                  })
                    .then(function (response) {
                      //console.log(response, response.status, response.data.length, response.data);

                      // nascondo preloader
                      preloaderRemoveWishlistPopup.classList.add("hidden");

                      if (200 === response.status) {
                        // mostro pulsante per rimuovere
                        var content = document.querySelector(
                          "#removeFromWishlistPopup .content"
                        );
                        content.classList.remove("hidden");

                        // aggiorno id interno della wishlist (wordpress)
                        var wishlistWPId = document.querySelector(
                          "#removeFromWishlistPopup #wishlistWPId"
                        );
                        wishlistWPId.value = response.data[0].id;

                        // metto json serializzato della wishlist in campo nascosto
                        var wishlistRows = document.querySelector(
                          "#removeFromWishlistPopup #wishlistRows"
                        );
                        wishlistRows.value = JSON.stringify(
                          response.data[0].acf.ecommerce_wishlist
                        );
                      } else {
                        resultMessage.classList.remove("hidden");
                        resultKo.classList.remove("hidden");
                        if (
                          resultOk &&
                          !resultOk.classList.contains("hidden")
                        ) {
                          resultOk.classList.add("hidden");
                        }
                      }
                    })
                    .catch(function (error) {
                      console.error(error);

                      resultMessage.classList.remove("hidden");
                      resultKo.classList.remove("hidden");
                      if (resultOk && !resultOk.classList.contains("hidden")) {
                        resultOk.classList.add("hidden");
                      }
                    });
                },
                close: function () {
                  // reset vari

                  preloaderRemoveWishlistPopup.classList.remove("hidden");

                  // messaggio
                  resultMessage.classList.add("hidden");

                  if (resultOk && !resultOk.classList.contains("hidden")) {
                    resultOk.classList.add("hidden");
                  }

                  if (resultKo && !resultKo.classList.contains("hidden")) {
                    resultKo.classList.add("hidden");
                  }
                },
              },
            });
          });
        }

        // pulsante rimuovi
        var removeArticleButton = document.querySelector(
          "#removeFromWishlistPopup .bottom button"
        );
        if (removeArticleButton) {
          removeArticleButton.addEventListener("click", function (e) {
            e.stopPropagation();

            // recupero la wishlist
            var wishlistUserName = document.querySelector("#currentUserName")
              .value;
            var wishlistId = "wishlist_" + wishlistUserName;

            // nonce
            var nonce = document.querySelector("#nonce").value;

            // nascondo content
            var content = document.querySelector(
              "#removeFromWishlistPopup .content"
            );
            content.classList.add("hidden");

            // mostro il preloader
            if (preloaderPopup && preloaderPopup.classList.contains("hidden")) {
              preloaderPopup.classList.remove("hidden");
            }

            // recupero json carrello e id della riga da rimuovere
            var modelRowId = document.querySelector(
              "#removeFromWishlistPopup #modelRowId"
            );
            var wishlistRows = document.querySelector(
              "#removeFromWishlistPopup #wishlistRows"
            );
            // deserializzo json
            var wishlistObj = JSON.parse(wishlistRows.value);
            //console.log(wishlistObj);

            // rimuovo ecommerce_model_id
            var newWishlistRows = wishlistObj.ecommerce_wishlist_rows;
            var removedRowElement;
            var myModelId = parseInt(modelRowId.value);
            console.info(newWishlistRows);
            newWishlistRows.map(function (e, index) {
              if (e.ecommerce_wishlist_rows_model === myModelId) {
                newWishlistRows.splice(index, 1);
              }
            });

            // preparo body
            var wishlistUserId = wishlistObj.ecommerce_wishlist_user;
            var postData = {
              title: wishlistId,
              acf: {
                ecommerce_wishlist: {
                  ecommerce_wishlist_user: parseInt(wishlistUserId),
                  ecommerce_wishlist_rows: newWishlistRows,
                },
              },
              status: "publish",
            };

            // baseUrl WP
            var baseUrlWP = document.querySelector("#baseUrlWP").value;

            // id interno wishlist (wordpress)
            var wishlistWPId = document.querySelector(
              "#removeFromWishlistPopup #wishlistWPId"
            );

            // messaggi d'errore o ok
            var resultMessage = document.querySelector(
              "#removeFromWishlistPopup .resultMessage"
            );
            var resultOk = resultMessage.querySelector(".message");
            var resultKo = resultMessage.querySelector(".error");

            // aggiorno il carrello
            axios({
              method: "put",
              url: baseUrlWP + "wp/v2/wishlist/" + wishlistWPId.value,
              data: postData,
              headers: {
                "Content-Type": "application/json",
                "X-WP-Nonce": nonce,
              },
            })
              .then(function (response) {
                // nascondo preloader
                preloaderPopup.classList.add("hidden");

                //console.log(response, response.status, response.data.length, response.data);

                if (200 === response.status) {
                  // se era l'ultimo record ricarico la pagina
                  var tableTrCount = document.querySelectorAll(
                    ".wishlistElement"
                  );
                  if (tableTrCount.length === 1) {
                    location.reload();
                    return;
                  }

                  // messaggio
                  if (
                    resultMessage &&
                    resultMessage.classList.contains("hidden")
                  ) {
                    resultMessage.classList.remove("hidden");
                  }

                  if (resultOk && resultOk.classList.contains("hidden")) {
                    resultOk.classList.remove("hidden");
                  }

                  if (resultKo) {
                    resultKo.classList.add("hidden");
                  }

                  // ricarico la pagina
                  setTimeout(function () {
                    location.reload();
                    return;
                  }, 1000);
                } else {
                  resultMessage.classList.remove("hidden");
                  resultKo.classList.remove("hidden");
                  if (resultOk && !resultOk.classList.contains("hidden")) {
                    resultOk.classList.add("hidden");
                  }
                }
              })
              .catch(function (error) {
                console.error(error);

                resultMessage.classList.remove("hidden");
                resultKo.classList.remove("hidden");
                if (resultOk && !resultOk.classList.contains("hidden")) {
                  resultOk.classList.add("hidden");
                }
              });
          });
        }

        // removeFromWishlist -> chiudi popup
        var removeFromWishlistClosePopup = document.querySelector(
          "#removeFromWishlistPopup .closePopup"
        );
        if (removeFromWishlistClosePopup) {
          removeFromWishlistClosePopup.addEventListener("click", function () {
            $.magnificPopup.close();
          });
        }
      },
      finalize: function () {},
    },
  }; // Sage

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  }; // UTIL

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
